import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Oval } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Image } from "antd";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import {
  deletePerkAPI,
  discountListHistoryAPI,
  poolPerksHistoryAPI,
  makePoolPerksArchiveAPI,
} from "../../API/PoolPerkRequest";
import { logoutAdmin } from "../../Action/authAction";
import { sessionExpire } from "../../Scripts/Helper";
import Container from "../Layouts/Container";
import { FaDownload } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { error } from "jquery";

const PoolPerksHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.authReducer.authData);
  const authToken = useSelector((state) => state.authReducer.token);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [poolPerkHistoryData, setPoolPerkHistoryData] = useState([]);
  const [nextPage, setNextPage] = useState("");
  const [searchCustomer, setSearchCustomer] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [orderBy, setOrderyBy] = useState("");
  const [currentView, setCurrentView] = useState(true);
  const [activeWhatsNew, setActiveWhatsNew] = useState([]);
  const [activePostData, setActivePostData] = useState({});
  const [activePageCount, setActivePageCount] = useState(0);
  const [pageNumber, setPagenumber] = useState(1);

  const handlePageChange = async (d) => {
    window.scrollTo(0, 0);
    var CurrentPage = d.selected + 1;
    setPagenumber(CurrentPage);
  };

  const handleSearchChange = (e) => {
    setSearchCustomer(e.target.value);
  };

  let fetchAllPerksList = async () => {
    setSpinnerLoading(true);

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        time_zone: userTimeZone,
      },
    };

    await poolPerksHistoryAPI(config, pageNumber,searchCustomer)
      .then((response) => {
        console.log(response,'ressssssssssssssponsee');
        
        setPoolPerkHistoryData(
          response ? (response.data ? response.data.data : []) : []
        );
        setActivePostData({
          count: response.data.data.count,
          currentPage: response.data.data.currentPage,
          totalPages: response.data.data.totalPages,
        });
        setActivePageCount(Math.ceil(response.data.data.count / 10));
      })
      .catch((err) => {
        console.error("Error fetching perk history:", err);

        if (err.code === "ERR_NETWORK") {
          toast.error("Something went wrong! Please try again later.");
        } else {
          let res = sessionExpire(err);
          toast.error(res.message);

          if (res.status) {
            dispatch(logoutAdmin());
            navigate("/login");
          }
        }
      });

    setSpinnerLoading(false);
  };

  // useEffect(() => {
  //     fetchAllPerksList();
  // }, []);

  useEffect(() => {
    //     if(nextPage == 0) {
    //         // setendCursor("")
    //         fetchCustomerList('',startCursor);
    //     } else{
    //         // setstartCursor("")
    //         fetchCustomerList(endCursor,'');
    //     }
    fetchAllPerksList();
  }, [pageNumber,searchCustomer]);

  const handleDeletePerk = async (e, id) => {
    e.preventDefault();

    Swal.fire({
      title: "Confirm delete?",
      text: "Do you really want to delete this perk?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then(async function (result) {
      if (result.isConfirmed) {
        setSpinnerLoading(true);

        const config = {
          headers: { Authorization: `Bearer ${authToken}` },
        };

        await deletePerkAPI(config, id)
          .then((res) => {
            Swal.fire(
              "Deleted!",
              "Perk has been deleted successfully.",
              "success"
            );
            toast.success(res.data.message);
            fetchAllPerksList();
          })
          .catch((err) => {
            console.error("Error deleting perk:", err);

            if (err.code === "ERR_NETWORK" || err.code === "ECONNABORTED") {
              toast.error("Something went wrong! Please try again later.");
            } else {
              toast.error(err.response.data.message);
            }
          });

        setSpinnerLoading(false);
      }
    });
  };

  const handleExport = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        time_zone: userTimeZone,
      },
    };

    try {
      const response = await discountListHistoryAPI(config,searchCustomer);

      console.log(searchCustomer,'searchCustomerrrrrr');
      

      if (response.status === 200 && response.data.status) {
        // const fileUrl = response.data.fileUrl;
        const fileUrl = process.env.REACT_APP_BASE_URL + response.data.fileUrl;
        window.open(fileUrl, "_blank");

        // const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);

        // const link = document.createElement('a');
        // link.href = fileUrl;
        // link.download = fileName;

        // document.body.appendChild(link);
        // link.click();

        // document.body.removeChild(link);
      } else {
        console.error("Export failed:", response.data.message);
        alert("Export failed. Please try again later.");
      }
    } catch (error) {
      console.error("Export failed:", error);
      alert("Export failed. Please try again later.");
    }
  };


  const handleArchives = async (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        time_zone: userTimeZone,
      },
    };

    // Show confirmation alert before proceeding
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You are about to archive this perk!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    });

    // If user cancels, stop the function
    if (!result.isConfirmed) {
      return;
    }

    try {
      await makePoolPerksArchiveAPI(config, id)
        .then((response) => {
          // console.log(response);
          Swal.fire({
            title: "Archived!",
            text: "The perk has been archived successfully.",
            icon: "success",
          });
          fetchAllPerksList();
        })
        .catch((error) => {
          console.error("Archived failed:", error);
        });
    } catch (error) {
      console.error("Archived failed:", error);
    }
  };

  const goToCurrentPage = () => {
    navigate("/pool-perks");
  };

  const goToArchivePage = () => {
    navigate("/pool-perks-archive");
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      {user && (
        <Container>
          <div className="nk-content mt-5 ">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head nk-block-head-sm mt-3">
                    <div className="nk-block-between">
                      <div className="nk-block-head-content">
                        <h4 className="nk-block-title page-title">
                          The Pool Store /<span> Pool Perks</span>
                        </h4>
                      </div>
                      {/* .nk-block-head-content */}
                      <div className="d-flex">
                        {/* <div className="drodown mr-3">
                                                    <a href="#" className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white exportDrop" data-bs-toggle="dropdown">Export </a>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <ul className="link-list-opt no-bdr">
                                                            <li><a href="#"><span>On Hold</span></a></li>
                                                            <li><a href="#"><span>Delivered</span></a></li>
                                                            <li><a href="#"><span>Rejected</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div> */}
                        <Link
                          to={"/add-perks"}
                          className="btn btn-primary d-md-inline-flex fRight"
                        >
                          Add New Perk
                        </Link>
                      </div>
                    </div>
                    {/* .nk-block-between */}
                  </div>
                  {/* .nk-block-head */}

                  <div className="nk-block">
                    <div className="row g-gs">
                      <div className="col-md-6">
                        {/* {poolPerkHistoryData?.length > 0 && ( */}

                        <div className="circleChartFlex">
                          <div className="">
                            <span>Pool Perks Activity</span>
                            <h6>
                              Showing all {poolPerkHistoryData.count} perks
                            </h6>
                          </div>
                          <div className="d-flex">
                            {/* <a href="" className="thiryday">30 Days</a> */}
                            <div className="drodown">
                              {/* <a className="dropdown-toggle btn btn-outline-light btn-white filterBnt" data-bs-toggle="dropdown" aria-expanded="false">Filter
                                                                <em className="icon ni ni-filter-alt"></em>
                                                            </a> */}
                              <div className="dropdown-menu dropdown-menu-end">
                                <ul className="link-list-opt no-bdr">
                                  <li>
                                    <a
                                      onClick={(e) => {
                                        setSortBy("updated_at");
                                        setNextPage("");
                                      }}
                                    >
                                      <input
                                        className="me-2"
                                        type="radio"
                                        checked={sortBy == "updated_at"}
                                        onChange={(e) => {
                                          setSortBy("updated_at");
                                          setNextPage("");
                                        }}
                                        name=""
                                        id=""
                                      />
                                      <label htmlFor="">Last update</label>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={(e) => {
                                        setSortBy("total_spent");
                                        setNextPage("");
                                      }}
                                    >
                                      <input
                                        className="me-2"
                                        type="radio"
                                        checked={sortBy == "total_spent"}
                                        onChange={(e) => {
                                          setSortBy("total_spent");
                                          setNextPage("");
                                        }}
                                        name=""
                                        id=""
                                      />
                                      <label htmlFor="">Amount spent</label>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={(e) => {
                                        setSortBy("orders_count");
                                        setNextPage("");
                                      }}
                                    >
                                      <input
                                        className="me-2"
                                        type="radio"
                                        checked={sortBy == "orders_count"}
                                        onChange={(e) => {
                                          setSortBy("orders_count");
                                          setNextPage("");
                                        }}
                                        name=""
                                        id=""
                                      />
                                      <label htmlFor="">Total orders</label>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={(e) => {
                                        setSortBy("created_at");
                                        setNextPage("");
                                      }}
                                    >
                                      <input
                                        className="me-2"
                                        type="radio"
                                        checked={sortBy == "created_at"}
                                        onChange={(e) => {
                                          setSortBy("created_at");
                                          setNextPage("");
                                        }}
                                        name=""
                                        id=""
                                      />
                                      <label htmlFor="">
                                        Date added as customer
                                      </label>
                                    </a>
                                  </li>
                                  <hr style={{ margin: "5px 0px" }} />
                                  <li>
                                    <a
                                      onClick={(e) => {
                                        setOrderyBy("asc");
                                        setNextPage("");
                                      }}
                                      className={
                                        orderBy == "asc" && "text-primary"
                                      }
                                    >
                                      <em className="icon ni ni-arrow-up"></em>
                                      <label htmlFor="">
                                        Lowest to highest
                                      </label>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={(e) => {
                                        setOrderyBy("desc");
                                        setNextPage("");
                                      }}
                                      className={
                                        orderBy == "desc" && "text-primary"
                                      }
                                    >
                                      <em className="icon ni ni-arrow-down"></em>
                                      <label htmlFor="">
                                        Highest to lowest
                                      </label>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="nk-block">
                        <div className="row g-gs">
                          <div className="col-md-6">
                            <div className="btn-group justify-content-start mr-2">
                              <div className="">
                                {/* <h6>Posts activity</h6> */}
                                {/* <h6>Showing 10 rows</h6> */}
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <button
                                  className="btn btn-outline-light btn-white px-5"
                                  onClick={goToCurrentPage}
                                  style={{
                                    marginRight: "15px",
                                    borderTopLeftRadius: "0px",
                                    borderBottomLeftRadius: "0px",
                                    background:
                                      currentView == false && "#526484",
                                    color: currentView == false && "#ffff",
                                  }}
                                >
                                  Current{" "}
                                </button>
                                <button
                                  className="btn btn-outline-light btn-white px-4"
                                  style={{
                                    marginRight: "auto",
                                    background: currentView && "#526484",
                                    color: currentView && "#fff",
                                  }}
                                >
                                  History
                                </button>
                                <div style={{ marginLeft: "auto" }}>
                                  <button
                                    className="btn btn-outline-light btn-white px-4"
                                    onClick={handleExport}
                                    style={{
                                      marginRight: "15px",
                                      width: "207px",
                                      background: currentView && "#526484",
                                      color: currentView && "#fff",
                                    }}
                                  >
                                    <FaDownload
                                      style={{ marginRight: "5px" }}
                                    />{" "}
                                    Download User Lists
                                  </button>
                                </div>
                                <button
                                  className="btn btn-outline-light btn-white px-4"
                                  onClick={goToArchivePage}
                                >
                                  Archive
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-control-wrap searchBarTable">
                              <div className="form-icon form-icon-right">
                                <em className="icon ni ni-search"></em>
                              </div>
                              <input
                                type="text"
                                value={searchCustomer}
                                onChange={handleSearchChange}
                                className="form-control"
                                id="fv-email"
                                name="fv-email"
                                placeholder="Search Here..."
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* .row */}
                  </div>
                  {spinnerLoading ? (
                    <div className="vh-100 flex justify-center items-center">
                      <Oval
                        height="100"
                        width="100"
                        color="var(--ps-main)"
                        secondaryColor="var(--ps-main)"
                        ariaLabel="oval-loading"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div
                      className="card card-preview"
                      style={{ overflowX: "scroll" }}
                    >
                      <table className="table table-orders mt-3">
                        <thead className="tb-odr-head dashboardTableHead">
                          <tr className="tb-odr-item">
                            <th className="tb-odr-info">
                              <span className="tb-odr-id">Image</span>
                            </th>
                            <th className="tb-odr-info">
                              <span className="tb-odr-id">Barcode</span>
                            </th>
                            <th className="tb-odr-amount">
                              <span className="tb-odr-total">Title</span>
                            </th>
                            <th className="tb-odr-amount">
                              <span className="tb-odr-total">Used Count</span>
                            </th>
                            <th className="tb-odr-amount">
                              <span className="tb-odr-status">Discount Id</span>
                            </th>
                            <th className="tb-odr-amount">
                              <span className="tb-odr-status">
                                Discount Value
                              </span>
                            </th>
                            <th className="tb-odr-amount">
                              <span className="tb-odr-status">
                                Discount Code
                              </span>
                            </th>
                            <th className="tb-odr-amount">
                              <span className="tb-odr-status">Status</span>
                            </th>
                            <th className="tb-odr-amount">
                              <span className="tb-odr-status">Start Date</span>
                            </th>
                            <th className="tb-odr-amount">
                              <span className="tb-odr-status">End Date</span>
                            </th>
                            <th className="tb-odr-amount">
                              <span className="tb-odr-status">Action</span>
                            </th>
                            {/* <th className="tb-odr-amount"><span className="tb-odr-status">Action</span></th> */}
                          </tr>
                        </thead>
                        <tbody className="tb-odr-body whatSnewTable">
                          {poolPerkHistoryData?.rows?.length > 0 &&
                            poolPerkHistoryData.rows.map((data) => (
                              <tr className="tb-odr-item" key={data.id}>
                                <td className="tb-odr-info">
                                  <Image
                                    src={data.image_url}
                                    style={{ width: "100px" }}
                                    alt=""
                                  />
                                </td>
                                <td className="tb-odr-info">
                                  <Image
                                    src={data.barcode_url}
                                    style={{
                                      width: "100px",
                                      background: "white",
                                    }}
                                    className="ant-image-preview-img"
                                    alt=""
                                  />
                                </td>
                                <td className="tb-odr-info">{data?.title}</td>
                                <td className="tb-odr-info">
                                  <Link
                                    to="/customer-history-info"
                                    state={{ perkId: data?.id }}
                                  >
                                    {data?.discount_user_count}
                                  </Link>
                                </td>
                                <td className="tb-odr-info">
                                  {data?.discount_id}
                                </td>
                                <td className="tb-odr-info">
                                  {data?.discount_value}
                                </td>
                                <td className="tb-odr-info">
                                  {data?.discount_code}
                                </td>
                                <td className="tb-odr-info">
                                  {data?.discount_status}
                                </td>
                                <td className="tb-odr-info">
                                  {moment(data?.start_date).format(
                                    "YYYY-MM-DD"
                                  )}
                                </td>
                                <td className="tb-odr-info">
                                  {moment(data?.end_date).format("YYYY-MM-DD")}
                                </td>
                                <td className="tb-odr-info">
                                  {/* <Link to={`/pool-perks-make-archive/${data.id}`}> */}
                                  <a
                                    className="text-soft btn btn-icon"
                                    onClick={() => handleArchives(data.id)}
                                  >
                                    <em className="icon ni ni-archive"></em>
                                  </a>
                                  {/* </Link> */}
                                </td>
                                {/* <td className="tb-odr-info">
                                     <Link to={`/edit-perks/${data.id}`} role='button' className="text-soft btn btn-icon">
                                        <em className="icon ni ni-edit"></em>
                                      </Link>
                                       <a onClick={e => handleDeletePerk(e, data.id)} className="text-soft btn btn-icon">
                                          <em className="icon ni ni-trash-empty"></em>
                                       </a>
                                </td> */}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {poolPerkHistoryData?.length === 0 && (
                        <div className="d-flex justify-content-center align-item-center mt-5">
                          <span>No Data Available</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                forcePage={pageNumber - 1}
                breakLabel={"..."}
                // pageCount={10}
                pageCount={activePageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={handlePageChange}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default PoolPerksHistory;
