import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Container from '../Layouts/Container';

const ComingSoonPage = ({ pageName }) => {

    const navigate = useNavigate()
    const user = useSelector((state) => state.authReducer.authData)

    const authToken = useSelector((state) => state.authReducer.token)

    useEffect(() => {
        !user && navigate('/login')
    }, []);

    return (
        <>
            {user &&
                <Container>
                    <div className='vh-100 d-flex flex-column justify-content-center align-items-center'>
                        <h3 style={{ fontFamily: "math" }}><b>{pageName} section is</b></h3>
                        <h3 style={{ fontFamily: "math" }}><b>Coming Soon...</b></h3>
                    </div>
                </Container>
            }
        </>
    );
}

export default ComingSoonPage;
