exports.sessionExpire = (err) => {

    let data = {
        status: false,
        message: err.response.data.message
    }

    if (err.response.status == 402) {
        data.status = true
    }

    return data
}