import React, { useEffect, useRef, useState } from 'react';
import { Oval } from 'react-loader-spinner'
import toast, { Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Container from '../Layouts/Container';
import { useDropzone } from 'react-dropzone';
import { logoutAdmin } from '../../Action/authAction';
import { sessionExpire } from '../../Scripts/Helper';
import { editDiscountBannerApi, singleDiscountBannerApi, poolPerksAllApi } from '../../API/DiscountsRequest';
import moment from 'moment-timezone';

const EditDiscountBanner = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const banner_id = useParams().id
    const imageRef = useRef(null)
    const user = useSelector((state) => state.authReducer.authData)
    const authToken = useSelector((state) => state.authReducer.token)

    // Get the user's time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [poolPerkData, setPoolPerkData] = useState([])

    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [bannerData, setBannerData] = useState({})
    const [bannerImage, setBannerImage] = useState("")
    const [startDate, setStartDate] = useState(bannerData.start_date)
    const [expiryDate, setExpiryDate] = useState(bannerData.end_date)

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            setBannerImage(acceptedFiles[0])
            editDiscountForm.setFieldValue("image", URL.createObjectURL(acceptedFiles[0]))
        },
    });

    let fetchScreenData = async () => {
        setSpinnerLoading(true)
        const config = {
            headers: { Authorization: `Bearer ${authToken}` }
        }
        await singleDiscountBannerApi(config, banner_id)
            .then((response) => {
                setBannerData(response ? response.data ? response.data.data : {} : {})
                setBannerImage(response.data.data.image_icon)
                setStartDate(response.data.data.start_date)
                setExpiryDate(response.data.data.end_date)

            }).catch((err) => {
                console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)

                if (err.code == "ERR_NETWORK") {
                    toast.error("Something went wront! please try again later")
                }
                else {
                    let res = sessionExpire(err)
                    toast.error(res.message)

                    if (res.status) {
                        dispatch(logoutAdmin())
                        navigate('/login')
                    }
                }
            })
        setSpinnerLoading(false)
    }

    let fetchPerksList = async () => {
        setSpinnerLoading(true)

        const config = {
            headers: {
                Authorization: `Bearer ${authToken}`,
                time_zone: userTimeZone,
            }
        }

        await poolPerksAllApi(config)
            .then((response) => {

                console.log(response,'response')
                setPoolPerkData(response ? response.data ? response.data.data : [] : [])

            }).catch((err) => {
                console.log("🚀 ~ .then ~ err:", err)

                if (err.code == "ERR_NETWORK") {  
                    toast.error("Something went wront! please try again later")
                }
                else {
                    let res = sessionExpire(err)
                    console.log("🚀 ~ .then ~ err:", err)
                    toast.error(res.message)

                    if (res.status) {
                        dispatch(logoutAdmin())
                        navigate('/login')
                    }
                }
            })
        setSpinnerLoading(false)
    }

    const editDiscountForm = useFormik({
        initialValues: {
            title: bannerData.title,
            description: bannerData.description,
            discount_id:bannerData.discount_id,
            image: bannerData.image_icon,
            start_date: bannerData.start_date,
            end_date: bannerData.end_date,
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            title: yup.string().required('Title is required'),
            description: yup.string().required('Description is required'),
            discount_id: yup.string().required('Discount is required'),
            image: yup.string().required('Image is required'),
            start_date: yup.string().required('Start Date is required'),
            end_date: yup.string().required('Expiration Date is required'),
        }),
        onSubmit: async (values, { resetForm }) => {
            setBtnLoading(true)

            const formData = new FormData();
            formData.append("title", editDiscountForm.getFieldProps('title').value)
            formData.append("description", editDiscountForm.getFieldProps('description').value)
            formData.append("discount_id", editDiscountForm.getFieldProps('discount_id').value)
            formData.append("image", bannerImage);
            formData.append("start_date", moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
            formData.append("end_date", moment(expiryDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));

            const config = {
                headers: { Authorization: `Bearer ${authToken}` }
            }
            await editDiscountBannerApi(formData, config, banner_id)
                .then((res) => {

                    navigate('/discount')

                    setTimeout(() => {
                        toast.success(res.data.message)
                    }, 100);

                }).catch((err) => {
                    console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)

                    if (err.code == "ERR_NETWORK") {
                        toast.error("Something went wront! please try again later")
                    }
                    else {
                        let res = sessionExpire(err)
                        toast.error(res.message)

                        if (res.status) {
                            dispatch(logoutAdmin())
                            navigate('/login')
                        }
                    }
                })

            setBtnLoading(false)
        }
    })

    useEffect(() => {
        !user && navigate('/login')
        fetchPerksList();
        fetchScreenData()
    }, []);

    return (
        <>
            {user &&
                <Container>
                    <Toaster position="top-right" reverseOrder={false} />
                    {spinnerLoading
                        ? <div className='vh-100 flex justify-center items-center'>
                            <Oval
                                height="100"
                                width="100"
                                color='var(--ps-main)'
                                secondaryColor="var(--ps-main)"
                                ariaLabel='oval-loading'
                                strokeWidth={4}
                                strokeWidthSecondary={4}
                                visible={true}
                            />
                        </div>
                        :
                        <div className="nk-content mt-5">
                            <div className="container-fluid mt-4">
                                <div className="nk-content-inner">
                                    <div className="nk-content-body">
                                        <div className="nk-block-head nk-block-head-sm">
                                            <div className="nk-block-between">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title page-title">The Pool Store /
                                                        <span> Edit Discount Banner</span>
                                                    </h4>
                                                </div>{/* .nk-block-head-content */}
                                            </div>{/* .nk-block-between */}
                                        </div>{/* .nk-block-head */}

                                        {/* <div className="nk-block-head nk-block-head-sm">
                                    <div className="nk-block-between">
                                        <div className="nk-block-head-content">
                                            <h3 className="nk-block-title page-title text-grey">Add New Screen</h3>
                                        </div>
                                        <div className="nk-block-head-content">
                                            <div className="toggle-wrap nk-block-tools-toggle">
                                                <ul className="nk-block-tools g-3">
                                                    <li>
                                                        <div className="drodown">
                                                            <a href="#" className="dropdown-toggle dropdown-indicator btn btn-outline-light slectDraftBnt" data-bs-toggle="dropdown">Save as Draft</a>
                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                <ul className="link-list-opt no-bdr">
                                                                    <li><a href="#"><span>On Hold</span></a></li>
                                                                    <li><a href="#"><span>Delevired</span></a></li>
                                                                    <li><a href="#"><span>Rejected</span></a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="nk-block-tools-opt">
                                                        <a href="#" className="btn btn-primary lightBlue d-md-inline-flex">Save & Publish</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}


                                        <div className="row g-gs mt-3">
                                            <div className="col-md-12">

                                                <div className="row g-gs">
                                                    <form className="row" onSubmit={editDiscountForm.handleSubmit}>


                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <div className="form-control-wrap whatNewInput">
                                                                    <label className="form-label">Title</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control form-control-lg"
                                                                        placeholder="Title"
                                                                        {...editDiscountForm.getFieldProps("title")}
                                                                    />
                                                                    {editDiscountForm.touched.title && editDiscountForm.errors.title
                                                                        ? <div className="invalid-feedback" style={{ display: "block" }}>
                                                                            {editDiscountForm.errors.title}
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="form-control-wrap whatNewInput">
                                                                    <label className="form-label">Description</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control form-control-lg"
                                                                        placeholder="Sub title"
                                                                        {...editDiscountForm.getFieldProps("description")}
                                                                    />
                                                                    {editDiscountForm.touched.description && editDiscountForm.errors.description
                                                                        ? <div className="invalid-feedback" style={{ display: "block" }}>
                                                                            {editDiscountForm.errors.description}
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                               <div className="form-control-wrap whatNewInput">
                                                                <label className="form-label">Discount</label>

                                                                    <select className="form-control form-control-lg" {...editDiscountForm.getFieldProps("discount_id")}>
                                                                        <option>Select Discount</option>
                                                                        {poolPerkData.map((perk, index) => (
                                                                            <option key={index} value={perk.id}>{perk.title}</option>
                                                                        ))}
                                                                        </select>

                                                                
                                                                    {editDiscountForm.touched.discount_id && editDiscountForm.errors.discount_id
                                                                        ? <div className="invalid-feedback" style={{ display: "block" }}>
                                                                            {editDiscountForm.errors.discount_id}
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="form-control-wrap whatNewInput">
                                                                    <label className="form-label">Start Date</label>
                                                                    <input
                                                                        type="date"
                                                                        className="form-control form-control-lg"
                                                                        min={moment.utc(moment()).tz(userTimeZone).format('YYYY-MM-DD')}
                                                                        value={moment(startDate).format('yyyy-MM-DD')}
                                                                        onChange={e => {
                                                                            setStartDate(moment.utc(moment(e.target.value)).tz(userTimeZone).format('YYYY-MM-DD'))
                                                                            editDiscountForm.setFieldValue("start_date", e.target.value)
                                                                            setExpiryDate("")
                                                                            editDiscountForm.setFieldValue("end_date", "")
                                                                        }}
                                                                    />
                                                                    {editDiscountForm.touched.start_date && editDiscountForm.errors.start_date
                                                                        ? <div className="invalid-feedback" style={{ display: "block" }}>
                                                                            {editDiscountForm.errors.start_date}
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="form-control-wrap whatNewInput">
                                                                    <label className="form-label">Expiration Date</label>
                                                                    <input
                                                                        type="date"
                                                                        min={moment.utc(moment().add(1, 'day')).tz(userTimeZone).format('YYYY-MM-DD')}
                                                                        value={moment(expiryDate).format('yyyy-MM-DD')}
                                                                        className="form-control form-control-lg"
                                                                        onChange={e => {
                                                                            setExpiryDate(moment.utc(moment(e.target.value)).tz(userTimeZone).format('YYYY-MM-DD'))
                                                                            editDiscountForm.setFieldValue("end_date", e.target.value)
                                                                        }}
                                                                    />
                                                                    {editDiscountForm.touched.end_date && editDiscountForm.errors.end_date
                                                                        ? <div className="invalid-feedback" style={{ display: "block" }}>
                                                                            {editDiscountForm.errors.end_date}
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">Discount Banner</label>
                                                                {bannerImage
                                                                    ?
                                                                    <div className=''>
                                                                        <img src={editDiscountForm.values.image} alt="Screen Image" style={{ width: "250px" }} />
                                                                        <em
                                                                            role="button"
                                                                            className="far fa-solid fa-2x fa-rectangle-xmark"
                                                                            style={{ position: "absolute" }}
                                                                            onClick={e => {
                                                                                setBannerImage("")
                                                                                editDiscountForm.setFieldValue("image", "")
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    : <div className=""  {...getRootProps()}>
                                                                        <input {...getInputProps()} />
                                                                        {isDragActive
                                                                            ?
                                                                            <div className='d-flex justify-content-center align-items-center border border-3 border-dashed rounded-3' style={{ width: "350px", height: "150px", backgroundColor: "whitesmoke" }}>
                                                                                <h6>Drop the image here...</h6>
                                                                            </div>
                                                                            : <div className='d-flex justify-content-center align-items-center border border-3 border-dashed rounded-3 flex-column p-2'>
                                                                                <img className="imgUploadIcon" src="/images/Image.png" alt="user-avatar" />
                                                                                <div className="btn uploadTxt">
                                                                                    <em className="icon ni ni-upload" /> Upload Image
                                                                                </div>
                                                                                <div className="dz-message" data-dz-message="">
                                                                                    <span className="dz-message-or">Select or drag & drop onboarding screen image here.</span><br />
                                                                                    <span className="dz-message-text">File Format jpeg, png Accepted Size 1000x1000</span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {editDiscountForm.touched.image && editDiscountForm.errors.image
                                                                            ? <div className="invalid-feedback" style={{ display: "block" }}>
                                                                                {editDiscountForm.errors.image}
                                                                            </div>
                                                                            : ''
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>

                                                            {btnLoading
                                                                ? <div className='flex justify-center items-center' style={{ width: "250px" }} >
                                                                    <Oval
                                                                        height="30"
                                                                        width="30"
                                                                        color='var(--ps-main)'
                                                                        secondaryColor="var(--ps-main)"
                                                                        ariaLabel='oval-loading'
                                                                        strokeWidth={4}
                                                                        strokeWidthSecondary={4}
                                                                        visible={true}
                                                                    />
                                                                </div>
                                                                : <button type="submit px-5" className="btn btn-primary d-inline" style={{ width: "250px" }} >Submit</button>
                                                            }
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Container>
            }
        </>
    );
}

export default EditDiscountBanner;
