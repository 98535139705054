import React, { useEffect, useRef, useState } from 'react';
import { Oval } from 'react-loader-spinner'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import toast, { Toaster } from 'react-hot-toast';

import Container from '../Layouts/Container';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate,Link } from 'react-router-dom';
import { dashboardApi } from '../../API/DashboardRequest';
import { logoutAdmin } from '../../Action/authAction';
import { sessionExpire } from '../../Scripts/Helper';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Dashboard = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const user = useSelector((state) => state.authReducer.authData)
    const authToken = useSelector((state) => state.authReducer.token)

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [dashboardData, setDashboardData] = useState([])
    // const [dateRange, setDateRange] = useState(30)

    const [selectedPeriod, setSelectedPeriod] = useState('Last 30 Days');
//   const [customerList, setCustomerList] = useState([]);

    const handlePeriodChange = (period,range) => {
        setSelectedPeriod(period);
        // setDateRange(range)

        fetchDashboardData(range)

    };

    const chartData = {
        labels: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
        datasets: [
            {
                label: 'Current Week',
                fill: false,
                lineTension: 0.5,
                borderWidth: 3,
                // data: [10, 19, 18, 1, 85, 47, 8],
                data: dashboardData?.currentWeekRevenueByDay?.map((data) => data.amount),
                borderColor: 'rgb(0, 75, 167)',
                backgroundColor: 'rgba(0, 75, 167, 0.5)',
            },
            {
                label: 'Previous Week',
                fill: false,
                lineTension: 0.5,
                borderWidth: 3,
                // data: [10, 12, 13, 14, 25, 40, 87],
                data: dashboardData?.lastWeekRevenueByDay?.map((data) => data.amount),
                borderColor: 'rgb(237, 27, 36)',
                backgroundColor: 'rgba(237, 27, 36, 0.5)',
            },
        ],
    };

    const chatOptions = {
        indexAxis: 'x',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                // text: 'Chart Title',
            },
        },
    };

    let fetchDashboardData = async (range=30) => {
        setSpinnerLoading(true)

        const config = {
            headers: {
                Authorization: `Bearer ${authToken}`,
                time_zone: userTimeZone,
            }
        }

        await dashboardApi(config,range)
            .then((response) => {

                setDashboardData(response ? response.data ? response.data.data : [] : [])

            }).catch((err) => {
                console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)

                if (err.code == "ERR_NETWORK" || err.code == "ECONNABORTED") {
                    toast.error("Something went wront! please try again later")
                }
                else {
                    let res = sessionExpire(err)
                    toast.error(res.message)

                    if (res.status) {
                        dispatch(logoutAdmin())
                        navigate('/login')
                    }
                }
            })


        setSpinnerLoading(false)
    }

    useEffect(() => {
        !user && navigate('/login')
        fetchDashboardData()
    }, []);

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {user &&
                <Container>
                    <div className="nk-content ">
                        <div className="container-fluid">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">
                                    <div className="nk-block-head nk-block-head-sm">
                                        <div className="nk-block-between">
                                            <div className="nk-block-head-content">
                                                <h4 className="nk-block-title page-title">Home / <span>The Pool Store</span></h4>
                                            </div>
                                                {/* <div class="nk-block-head-content">
                                                    <div class="toggle-wrap nk-block-tools-toggle">
                                                        <a href="#" class="btn btn-icon btn-trigger toggle-expand me-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
                                                        <div class="toggle-expand-content" data-content="pageMenu">
                                                            <ul class="nk-block-tools g-3">
                                                                <li>
                                                                    <div class="drodown">
                                                                        <a href="#" class="dropdown-toggle btn btn-white btn-dim btn-outline-light" data-bs-toggle="dropdown" aria-expanded="false"><em class="d-none d-sm-inline icon ni ni-calender-date"></em><span><span class="d-none d-md-inline">Last</span> 30 Days</span><em class="dd-indc icon ni ni-chevron-right"></em></a>
                                                                        <div class="dropdown-menu dropdown-menu-end" >
                                                                            <ul class="link-list-opt no-bdr">
                                                                                <li><a href="#"><span>Last 30 Days</span></a></li>
                                                                                <li><a href="#"><span>Last 6 Months</span></a></li>
                                                                                <li><a href="#"><span>Last 1 Years</span></a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                                <div className="nk-block-head-content">
                                                                    <div className="toggle-wrap nk-block-tools-toggle">
                                                                        <a href="#" className="btn btn-icon btn-trigger toggle-expand me-n1" data-target="pageMenu">
                                                                        <em className="icon ni ni-more-v"></em>
                                                                        </a>
                                                                        <div className="toggle-expand-content" data-content="pageMenu">
                                                                        <ul className="nk-block-tools g-3">
                                                                            <li>
                                                                            <div className="dropdown">
                                                                                <button
                                                                                className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                                                                                type="button"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                                >
                                                                                <em className="d-none d-sm-inline icon ni ni-calender-date"></em>
                                                                                <span>
                                                                                    <span className="d-none d-md-inline">{selectedPeriod}</span>
                                                                                </span>
                                                                                <em className="dd-indc icon ni ni-chevron-right"></em>
                                                                                </button>
                                                                                <div className="dropdown-menu dropdown-menu-end">
                                                                                <ul className="link-list-opt no-bdr">
                                                                                    <li>
                                                                                    <Link onClick={() => handlePeriodChange('Last 30 Days',30)}>
                                                                                        <span>Last 30 Days</span>
                                                                                    </Link>
                                                                                    </li>
                                                                                    <li>
                                                                                    <Link onClick={() => handlePeriodChange('Last 6 Months',6)}>
                                                                                        <span>Last 6 Months</span>
                                                                                    </Link>
                                                                                    </li>
                                                                                    <li>
                                                                                    <Link onClick={() => handlePeriodChange('Last 1 Year',1)}>
                                                                                        <span>Last 1 Year</span>
                                                                                    </Link>
                                                                                    </li>
                                                                                </ul>
                                                                                </div>
                                                                            </div>
                                                                            </li>
                                                                        </ul>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                        </div>{/* .nk-block-between */}
                                    </div>{/* .nk-block-head */}

                                    {spinnerLoading
                                        ? <div className='flex justify-center items-center' style={{ height: "65vh" }}>
                                            <Oval
                                                height="100"
                                                width="100"
                                                color='var(--ps-main)'
                                                secondaryColor="var(--ps-main)"
                                                ariaLabel='oval-loading'
                                                strokeWidth={4}
                                                strokeWidthSecondary={4}
                                                visible={true}
                                            />
                                        </div>
                                        : <>
                                            <div className="nk-block">
                                                <div className="row g-gs">
                                                    <div className="col-md-12">
                                                        <div className="row g-gs">

                                                           



                                                            {/* <div className="col-xxl-3 col-sm-4">
                                                        <div className="card chartOneWhite">
                                                            <div className="nk-ecwg nk-ecwg6">
                                                                <div className="card-inner">
                                                                    <div className="card-title-group">
                                                                        <div className="card-title">
                                                                            <h6 className="title">Revenue</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="data">
                                                                        <div className="data-group">
                                                                            <div className="amount">$695</div>
                                                                            <div className="nk-ecwg6-ck">
                                                                                <span className="change up fontsize text-grey">-0.56% <em className="icon ni ni-trend-down"></em></span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-3 col-sm-4">
                                                        <div className="card chartOnegrey">
                                                            <div className="nk-ecwg nk-ecwg6">
                                                                <div className="card-inner">
                                                                    <div className="card-title-group">
                                                                        <div className="card-title">
                                                                            <h6 className="title">Growth</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="data">
                                                                        <div className="data-group">
                                                                            <div className="amount">30.1%</div>
                                                                            <div className="nk-ecwg6-ck">
                                                                                <span className="change up fontsize text-grey">+1.48% <em className="icon ni ni-trend-up"></em></span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-3 col-sm-4">
                                                        <div className="card chartOnegrey">
                                                            <div className="nk-ecwg nk-ecwg6">
                                                                <div className="card-inner">
                                                                    <div className="card-title-group">
                                                                        <div className="card-title">
                                                                            <h6 className="title">Growth</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="data">
                                                                        <div className="data-group">
                                                                            <div className="amount">30.1%</div>
                                                                            <div className="nk-ecwg6-ck">
                                                                                <span className="change up fontsize text-grey">+1.48% <em className="icon ni ni-trend-up"></em></span>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}


                                                        </div>
                                                    </div>


                                                    {/* <div className="col-md-5">
                                                <div className="card chartOneWhite">
                                                    <div className="card-inner">
                                                        <h6>Total Leads</h6>
                                                        Chart Design
                                                    </div>
                                                </div>
                                            </div> */}

                                                </div>{/* .row */}
                                            </div>


                                            <div className="nk-block">
                                                <div className="row g-gs">
                                                    
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <div className="col-6 col-sm-12">
                                                                <div className="card chartOne mb-3">
                                                                    <div className="nk-ecwg nk-ecwg6">
                                                                        <div className="card-inner">
                                                                            <div className="card-title-group">
                                                                                <div className="card-title w-100">
                                                                                    <h6 className="title text-center">Customers</h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className="data">
                                                                                <div className="data-group justify-content-center">
                                                                                    <div className="amount" style={{ fontSize: "16px" }}><em className="icon ni ni-user-group-fill" /> {dashboardData.totalCustomers ? dashboardData.totalCustomers : "--"}</div>
                                                                                    {/* <div className="nk-ecwg6-ck">
                                                                                <span className="change up text-grey">+5.27% <em className="icon ni ni-trend-up"></em></span>
                                                                            </div> */}
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-6 col-sm-12">
                                                                <div className="card chartOneWhite mb-3">
                                                                    <div className="nk-ecwg nk-ecwg6">
                                                                        <div className="card-inner">
                                                                            <div className="card-title-group">
                                                                                <div className="card-title w-100">
                                                                                    <h6 className="title text-center">Orders</h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className="data">
                                                                                <div className="data-group justify-content-center">
                                                                                    <div className="amount" style={{ fontSize: "16px" }}><em className="icon ni ni-bag-fill" /> {dashboardData.totalOrders ? dashboardData.totalOrders : "--"}</div>
                                                                                    {/* <div className="nk-ecwg6-ck">
                                                                                <span className="change up fontsize text-grey">+1.78% <em className="icon ni ni-trend-up"></em></span>
                                                                            </div> */}
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-12">
                                                                <div className="card chartOneWhite mb-3">
                                                                    <div className="nk-ecwg nk-ecwg6">
                                                                        <div className="card-inner">
                                                                            <div className="card-title-group">
                                                                                <div className="card-title w-100">
                                                                                    <h6 className="title text-center">Revenue</h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className="data">
                                                                                <div className="data-group justify-content-center">
                                                                                    <div className="amount" style={{ fontSize: "16px" }}><em class="icon ni ni-sign-usdc"></em> {dashboardData.totalShopifyRevenue ? parseInt(dashboardData.totalShopifyRevenue) : "--"}</div>
                                                                                    {/* <div className="nk-ecwg6-ck">
                                                                                <span className="change up fontsize text-grey">+1.78% <em className="icon ni ni-trend-up"></em></span>
                                                                            </div> */}
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                    
                                                    
                                                    <div className="col-md-6">
                                                        <div className="card revenueBoxColor">
                                                            <div className="card-inner">
                                                                <div className="card-title-group mt-n1">
                                                                    <div className="revenurTitlerow">
                                                                        <h5 className="rLine">Revenue</h5>
                                                                        <span className="currentWeek"><em className="icon ni ni-dot"></em> Current Week ${dashboardData.currentWeekShopifyRevenue ? dashboardData.currentWeekShopifyRevenue : "0"}</span>
                                                                        <span className="previousWeek"> <em className="icon ni ni-dot"></em>Previous Week ${dashboardData.lastWeekShopifyRevenue ? dashboardData.lastWeekShopifyRevenue : "0"}</span>
                                                                    </div>
                                                                    {/* <div className="card-tools me-n1">
                                                                <div className="dropdown">
                                                                    <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <em className="icon ni ni-more-h"></em></a>
                                                                    <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                                                                        <ul className="link-list-opt no-bdr">
                                                                            <li><a href="#" className="active"><span>15 Days</span></a></li>
                                                                            <li><a href="#"><span>30 Days</span></a></li>
                                                                            <li><a href="#"><span>3 Months</span></a></li></ul>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                                </div>

                                                                {/* Chart Start here Design */}
                                                                <Line options={chatOptions} data={chartData} />
                                                            </div>

                                                        </div>
                                                    </div>


                                                    {/* <div className="col-md-5">
                                                <div className="row g-gs">
                                                    <div className="col-md-12">
                                                        <div className="card chartOneWhite">
                                                            <div className="card-inner">
                                                                <h6>Revenue by Location</h6>
                                                                <div className="project-progress">
                                                                    <div className="project-progress-details">
                                                                        <div className="project-progress-task"><span>New York</span></div>
                                                                        <div className="project-progress-percent">72K</div>
                                                                    </div>
                                                                    <div className="progress progress-pill progress-md bg-light progress-md-Revnue"><div className="progress-bar fillRevnue" data-progress="93.5"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="project-progress">
                                                                    <div className="project-progress-details">
                                                                        <div className="project-progress-task"><span>San Francisco</span></div>
                                                                        <div className="project-progress-percent">39K</div>
                                                                    </div>
                                                                    <div className="progress progress-pill progress-md bg-light progress-md-Revnue"><div className="progress-bar fillRevnue" data-progress="93.5"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="project-progress">
                                                                    <div className="project-progress-details">
                                                                        <div className="project-progress-task"><span>Sydney
                                                                        </span></div>
                                                                        <div className="project-progress-percent">25K</div>
                                                                    </div>
                                                                    <div className="progress progress-pill progress-md bg-light progress-md-Revnue"><div className="progress-bar fillRevnue" data-progress="93.5"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="card chartOneWhite">
                                                            <div className="card-inner circleChartFlex">
                                                                <div className="revenuChartLocation">
                                                                    <h6>Revenue by Location</h6>
                                                                    <ul className="nk-ecwg4-legends pt-0">
                                                                        <li><div className="title"><span className="dot dot-lg sq" data-bg="#9cabff"></span><span>Direct Search</span></div><div className="amount amount-xs">$300.56</div></li>
                                                                        <li><div className="title"><span className="dot dot-lg sq" data-bg="#ffa9ce"></span><span>Affilliate</span></div><div className="amount amount-xs">$135.18</div></li>
                                                                        <li><div className="title"><span className="dot dot-lg sq" data-bg="#b8acff"></span><span>E-mail</span></div><div className="amount amount-xs">$48.96</div></li>
                                                                    </ul>
                                                                </div>
                                                                <div className="circleChartSmall">
                                                                    <img className="" src="./images/circlechart.png" alt="user-avatar" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                                </div>
                                            </div>


                                            <div className="nk-block">
                                                <div className="row g-gs">
                                                    <div className="col-md-6">
                                                        <div className="circleChartFlex">
                                                            <div className="">
                                                                <span>Ecommerce activity</span>
                                                                <h6>Top selling products</h6>
                                                            </div>

                                                            {/* <div className="drodown">
                                                        <a href="#" className="dropdown-toggle btn btn-outline-light btn-white" data-bs-toggle="dropdown" aria-expanded="false">Filter
                                                            <em className="icon ni ni-filter-alt"></em></a>
                                                        <div className="dropdown-menu dropdown-menu-end">
                                                            <ul className="link-list-opt no-bdr">
                                                                <li><a href="#"><span>- Price Low to high</span></a></li>
                                                                <li><a href="#"><span>- Price High to Low</span></a></li>
                                                            </ul>
                                                        </div>
                                                    </div> */}
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-6">
                                                <div className="form-control-wrap searchBarTable">
                                                    <div className="form-icon form-icon-right">
                                                        <em className="icon ni ni-search"></em></div>
                                                    <input type="text" className="form-control" id="fv-email" name="fv-email" placeholder='Search Here...' />
                                                </div>
                                            </div> */}

                                                </div>{/* .row */}

                                                <div className="row g-gs">
                                                    <div className="col-md-12">
                                                        <div className="card card-preview">
                                                           <div className="table-responsive">
                                                              <table className="table table-orders mt-3">
                                                                <thead className="tb-odr-head dashboardTableHead">
                                                                    <tr className="tb-odr-item">
                                                                        <th className="tb-odr-info"><span className="tb-odr-id">Product name</span></th>

                                                                        <th className="tb-odr-info"><span className="tb-odr-date d-none d-md-inline-block">Price</span></th>

                                                                        <th className="tb-odr-amount"><span className="tb-odr-total">Quantity</span></th>

                                                                        <th className="tb-odr-amount"><span className="tb-odr-status d-md-inline-block">Vendor</span></th>

                                                                        <th className="tb-odr-action">&nbsp;</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="tb-odr-body">
                                                                    {dashboardData?.topSellingProducts?.length > 0 &&
                                                                        dashboardData.topSellingProducts.map(data => {
                                                                            return <tr key={data.id} className="tb-odr-item">
                                                                                <td className="tb-odr-info">{data.title}</td>
                                                                                <td className="tb-odr-amount">{data.price}</td>
                                                                                <td className="tb-odr-amount">{data.numberOfPurchases}</td>
                                                                                <td className="tb-odr-amount">{data.vendor}</td>
                                                                            </tr>
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>    
                                        </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>}
        </>
    );
}

export default Dashboard;
