import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid" // needed for dayClick
import Swal from 'sweetalert2';
import { Oval } from 'react-loader-spinner'
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Container from '../Layouts/Container';
import { deleteWhatsNewPostAPI, whatsNewAPI, whatsNewListAPI } from '../../API/whatsNewRequest';
import moment from 'moment-timezone';
import ReactPaginate from 'react-paginate';
import { logoutAdmin } from '../../Action/authAction';
import { sessionExpire } from '../../Scripts/Helper';

const WhatsNew = () => {

    const navigate = useNavigate()
    const imageRef = useRef(null)
    const calendarRef = useRef(null);
    const time_zone = moment.tz.guess()
    const dispatch = useDispatch()

    const user = useSelector((state) => state.authReducer.authData)
    const authToken = useSelector((state) => state.authReducer.token)

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [seeMore, setSeeMore] = useState(false)
    const [navTabs, setNavTabs] = useState({ active: true, inactive: false, default: false })
    const [calendarView, setCalendarView] = useState(false)

    const [pageNumber, setPagenumber] = useState(1)
    const [sortBy, setSortBy] = useState('id')
    const [orderBy, setOrderyBy] = useState('DESC')
    const [searchPost, setSearchPost] = useState('')
    const [currentMonth, setCurrentMonth] = useState(moment().format("MM"))
    const [currentYear, setCurrentYear] = useState(moment().format("YYYY"))

    const [activeWhatsNew, setActiveWhatsNew] = useState([])
    const [activePostData, setActivePostData] = useState({})
    const [activePageCount, setActivePageCount] = useState(0)

    const [expiredWhatsNew, setExpiredWhatsNew] = useState([])
    const [expiredPostData, setExpiredPostData] = useState({})
    const [expiredPageCount, setExpiredPageCount] = useState(0)

    const [defaultWhatsNew, setDefaultWhatsNew] = useState([])
    const [defaultPostData, setDefaultPostData] = useState({})
    const [defaultPageCount, setDefaultPageCount] = useState(0)

    let fetchWhatsNewData = async () => {
        setSpinnerLoading(true)

        const config = {
            headers: {
                Authorization: `Bearer ${authToken}`,
                time_zone: userTimeZone,
            }
        }
        if (calendarView) {
            // await whatsNewAPI(pageNumber, searchPost, sortBy, orderBy, config, time_zone, currentMonth, currentYear)
            await whatsNewAPI(config, time_zone, currentMonth, currentYear)
                .then((response) => {

                    setActiveWhatsNew(response ? response.data ? response.data.data.response_active.rows : [] : [])
                    setActivePostData({
                        count: response.data.data.response_active.count,
                        currentPage: response.data.data.response_active.currentPage,
                        totalPages: response.data.data.response_active.totalPages,
                    })
                    setActivePageCount(Math.ceil(response.data.data.response_active.count / 10))

                    setExpiredWhatsNew(response ? response.data ? response.data.data.response_expired.rows : [] : [])
                    setExpiredPostData({
                        count: response.data.data.response_expired.count,
                        currentPage: response.data.data.response_expired.currentPage,
                        totalPages: response.data.data.response_expired.totalPages,
                    })
                    setExpiredPageCount(Math.ceil(response.data.data.response_expired.count / 10))

                    setDefaultWhatsNew(response ? response.data ? response.data.data.response_default.rows : [] : [])
                    setDefaultPostData({
                        count: response.data.data.response_default.count,
                        currentPage: response.data.data.response_default.currentPage,
                        totalPages: response.data.data.response_default.totalPages,
                    })
                    setDefaultPageCount(Math.ceil(response.data.data.response_default.count / 10))

                }).catch((err) => {
                    console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)

                    if (err.code == "ERR_NETWORK" || err.code == "ECONNABORTED") {
                        toast.error("Something went wront! please try again later")
                    }
                    else {
                        let res = sessionExpire(err)
                        toast.error(res.message)

                        if (res.status) {
                            dispatch(logoutAdmin())
                            navigate('/login')
                        }
                    }
                })
        }
        else {
            await whatsNewListAPI(pageNumber, searchPost, sortBy, orderBy, config, time_zone)
                .then((response) => {

                    setActiveWhatsNew(response ? response.data ? response.data.data.response_active.rows : [] : [])
                    setActivePostData({
                        count: response.data.data.response_active.count,
                        currentPage: response.data.data.response_active.currentPage,
                        totalPages: response.data.data.response_active.totalPages,
                    })
                    setActivePageCount(Math.ceil(response.data.data.response_active.count / 10))

                    setExpiredWhatsNew(response ? response.data ? response.data.data.response_expired.rows : [] : [])
                    setExpiredPostData({
                        count: response.data.data.response_expired.count,
                        currentPage: response.data.data.response_expired.currentPage,
                        totalPages: response.data.data.response_expired.totalPages,
                    })
                    setExpiredPageCount(Math.ceil(response.data.data.response_expired.count / 10))

                    setDefaultWhatsNew(response ? response.data ? response.data.data.response_default.rows : [] : [])
                    setDefaultPostData({
                        count: response.data.data.response_default.count,
                        currentPage: response.data.data.response_default.currentPage,
                        totalPages: response.data.data.response_default.totalPages,
                    })
                    setDefaultPageCount(Math.ceil(response.data.data.response_default.count / 10))

                }).catch((err) => {
                    console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)

                    if (err.code == "ERR_NETWORK" || err.code == "ECONNABORTED") {
                        toast.error("Something went wront! please try again later")
                    }
                    else {
                        let res = sessionExpire(err)
                        toast.error(res.message)

                        if (res.status) {
                            dispatch(logoutAdmin())
                            navigate('/login')
                        }
                    }
                })
        }

        setSpinnerLoading(false)
    }

    useEffect(() => {
        !user && navigate('/login')
        fetchWhatsNewData()
    }, [pageNumber, searchPost, sortBy, orderBy, currentMonth, currentYear, calendarView]);


    const handleDeleteWhatsNew = async (e, id) => {

        e.preventDefault();
        Swal.fire({
            title: 'Confirm delete?',
            text: "Do you really want to delete this post?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then(async function (result) {
            if (result.value) {
                setSpinnerLoading(true)

                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                };
                await deleteWhatsNewPostAPI(id, config)
                    .then((res) => {
                        Swal.fire('Deleted!', 'Post has been deleted successfully.', 'success');
                        toast.success(res.data.message)
                        fetchWhatsNewData()
                    }).catch((err) => {
                        console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)
                        if (err.code == "ERR_NETWORK" || err.code == "ECONNABORTED") {
                            toast.error("Something went wront! please try again later")
                        }
                        else {
                            toast.error(err.response.data.message)
                        }
                    })
                setSpinnerLoading(false)
            }
        })
    }

    const handlePageChange = async (d) => {
        window.scrollTo(0, 0);
        var CurrentPage = d.selected + 1;
        setPagenumber(CurrentPage)

    }

    // ---------------Logic to show image in calendar----------------
    const generateEventsWithImages = (posts) => {
        const calendarApi = calendarRef.current?.getApi();

        if (calendarApi?.view.type == 'timeGridDay') {
            return posts.map((post) => ({
                title: post.title,
                start: post.start_date_time, // Set the start date as needed
                rendering: 'background',
                extendedProps: {
                    imageUrl: post.image_icon,
                },
            }));
        }
        else {
            const uniqueDates = [...new Set(posts.map((post) => post.start_date_time.split('T')[0]))];

            return uniqueDates.map((date) => {
                const postsOnDate = posts.filter((post) => post.start_date_time.split('T')[0] === date);
                const combinedTitle = postsOnDate.map((post) => post.title).join(', ');

                return {
                    title: combinedTitle,
                    start: date,
                    count: postsOnDate.length, // Add count property
                    rendering: 'background',
                    extendedProps: {
                        imageUrl: postsOnDate[0].image_icon,
                    },
                };
            });
        }
    };

    const events = generateEventsWithImages(navTabs.active ? activeWhatsNew : navTabs.inactive ? expiredWhatsNew : defaultWhatsNew)

    const eventContent = (eventInfo) => {

        const { view, event } = eventInfo;

        const handleEventContentClick = () => {
            if (view.type === 'dayGridMonth') {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.gotoDate(event.start); // Go to the clicked event's start date
                calendarApi.changeView('timeGridDay'); // Change the view to timeGridDay
                setNavTabs(navTabs.active ? { active: true } : navTabs.inactive ? { inactive: true } : { default: true });
            }
        };

        if (view.type === 'dayGridMonth') {

            return <div role="button" onClick={handleEventContentClick} className='d-flex justify-content-center cursor' style={{ position: "relative", backgroundColor: "white", border: "0px" }}>
                {event.extendedProps.count > 1
                    ? <>
                        <img src={event.extendedProps.imageUrl} alt={event.title} style={{ width: '100%', objectFit: 'cover', position: "absolute", display: "block", opacity: ".6", backgroundColor: "white" }} />
                        <div className='d-flex justify-content-center align-items-center' style={{ fontSize: "1rem", color: "black", width: '200px', height: "110px", zIndex: "99" }}>
                            <span className='d-flex justify-content-center align-items-center' style={{ width: "90px", border: "2px solid #014EAD", borderRadius: "10px", backgroundColor: "#014EAD", color: "whitesmoke", opacity: ".85" }}>
                                +{event.extendedProps.count - 1} Posts
                            </span>
                        </div>
                    </>
                    : <>
                        <img src={event.extendedProps.imageUrl} alt={event.title} style={{ width: '100%', objectFit: 'cover' }} />
                    </>
                }
            </div>

        }
        else {

            // Convert UTC time to local time
            const localTime = moment.utc(moment(event.start).format("yyyy-MM-DDTHH:mm"), 'YYYY-MM-DDTHH:mm:ss');
            const formattedTime = localTime.tz(userTimeZone).format('hh:mm A');

            return <div className='' style={{ display: "flex", alignItems: "center" }}>
                <img src={event.extendedProps.imageUrl} alt={event.title} style={{ width: '40px' }} />
                <div>
                    <span className='dateSlotbOX mx-2 my-1 px-2 py-1' style={{ backgroundColor: "#004ba7", color: "white", borderRadius: "10px" }}>{event.title}</span>
                    <span className='timeSlotbOX mx-2 my-1 px-2 py-1' style={{ backgroundColor: "#004ba7", color: "white", borderRadius: "10px" }}>
                        {formattedTime}
                    </span>
                </div>
            </div>

        }
    }

    const dayCellContent = ({ date, view }) => {
        return <div className='d-flex justify-content-center' style={{ color: "white" }}>
            {date.getDate()}
        </div>;
    };

    const handleDateClick = (info) => {
        console.log("🚀 ~ file: WhatsNew.jsx:231 ~ handleDateClick ~ info:", info)
        const calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(info.date); // Go to the clicked date
        calendarApi.changeView('timeGridDay'); // Change the view to timeGridDay
        setNavTabs(navTabs.active ? { active: true } : navTabs.inactive ? { inactive: true } : { default: true })
    };

    const handleDatesSet = (arg) => {
        const calendarApi = calendarRef.current?.getApi();
        if (calendarApi) {
            setCurrentMonth(moment(calendarApi.getDate()).format("MM"))
            setCurrentYear(moment(calendarApi.getDate()).format("YYYY"))
        }
    };

    const handleCustomMonthButton = (info) => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.changeView('dayGridMonth'); // Change the view to timeGridDay
        setNavTabs(navTabs.active ? { active: true } : navTabs.inactive ? { inactive: true } : { default: true })
    };

    return (
        <>
            {user &&
                <Container>
                    <Toaster position="top-right" reverseOrder={false} />
                    <div className="nk-content">
                        <div className="container-fluid">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">
                                    <div className="nk-block-head nk-block-head-sm">
                                        <div className="nk-block-between">
                                            <div className="nk-block-head-content">
                                                <h4 className="nk-block-title page-title">The Pool Store /
                                                    <span>What’s New</span>
                                                </h4>
                                            </div>{/* .nk-block-head-content */}
                                        </div>{/* .nk-block-between */}
                                    </div>{/* .nk-block-head */}


                                    <div className="nk-block">
                                        <div className="row g-gs">
                                            <div className="col-md-9">
                                                <div className="circleChartFlex">
                                                    <div className="">
                                                        {/* <h6>Posts activity</h6> */}
                                                        {/* <h6>Showing 10 rows</h6> */}
                                                    </div>

                                                    <div className="">
                                                        <button className="btn btn-outline-light btn-white px-5" onClick={() => setCalendarView(false)} style={{ borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", background: calendarView == false && "#526484", color: calendarView == false && "#ffff" }}>List</button>
                                                        <button className="btn btn-outline-light btn-white px-4" onClick={() => setCalendarView(true)} style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px", background: calendarView && "#526484", color: calendarView && "#fff" }}>Calendar</button>
                                                    </div>

                                                    {/* <div className="d-flex">
                                                        <a href="" className="thiryday">30 Days</a>
                                                        <div className="drodown">
                                                            <a href="#" className="dropdown-toggle btn btn-outline-light btn-white filterBnt" data-bs-toggle="dropdown" aria-expanded="false">Filter
                                                                <em className="icon ni ni-filter-alt"></em></a>
                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                <ul className="link-list-opt no-bdr">
                                                                    <li><a href="#"><span>- Price Low to high</span></a></li>
                                                                    <li><a href="#"><span>- Price High to Low</span></a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-control-wrap searchBarTable">
                                                    <div className="form-icon form-icon-right">
                                                        <em className="icon ni ni-search"></em>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={searchPost}
                                                        onChange={e => setSearchPost(e.target.value)}
                                                        placeholder='Search Here...'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="nk-block">
                                        <div className="row g-gs">
                                            <div className="col-md-10">
                                                <ul className="nav nav-tabs mt-n3 brBtmNone" role="tablist">

                                                    <li className="nav-item" role="presentation" onClick={e => { setPagenumber(1); setSortBy('id'); setOrderyBy('DESC'); setNavTabs({ active: true }); setSeeMore(false) }}>
                                                        <a className={navTabs.active ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="#tabItem1" aria-selected="true" role="tab">Active & Schedule Posts
                                                            <span className="tableTabsSpan">{activePostData.count}</span>
                                                        </a>
                                                    </li>

                                                    <li className="nav-item" role="presentation" onClick={e => { setPagenumber(1); setSortBy('id'); setOrderyBy('DESC'); setNavTabs({ inactive: true }); setSeeMore(false) }}>
                                                        <a className={navTabs.inactive ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="#tabItem2" aria-selected="false" role="tab" tabIndex="-1">Expired Posts
                                                            <span className="tableTabsSpan">{expiredPostData.count}</span>
                                                        </a>
                                                    </li>

                                                    <li className="nav-item" role="presentation" onClick={e => { setPagenumber(1); setSortBy('id'); setOrderyBy('DESC'); setNavTabs({ default: true }); setSeeMore(false) }}>
                                                        <a className={navTabs.default ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="#tabItem3" aria-selected="false" role="tab" tabIndex="-1">Default Posts
                                                            <span className="tableTabsSpan">{defaultPostData.count}</span>
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>
                                            <div className="col-md-2">
                                                <Link to={"/add-new-post"} className="btn btn-primary lightBlue d-md-inline-flex fRight">Add New Post</Link>
                                            </div>

                                            {calendarView
                                                ? <FullCalendar
                                                    ref={calendarRef}
                                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                                    initialView={'dayGridMonth'}
                                                    customButtons={{
                                                        customMonthButton: {
                                                            text: 'Month',
                                                            click: handleCustomMonthButton,
                                                        },
                                                    }}
                                                    headerToolbar={{
                                                        start: 'today prev,next',
                                                        center: 'title',
                                                        end: 'customMonthButton',
                                                    }}
                                                    buttonText={{
                                                        today: 'Today',
                                                        month: 'Month',
                                                        week: 'Week',
                                                        day: 'Day',
                                                        list: 'List'
                                                    }}
                                                    events={events}
                                                    eventContent={eventContent}
                                                    dayCellContent={dayCellContent}
                                                    dateClick={handleDateClick}
                                                    datesSet={handleDatesSet}
                                                />
                                                : <>
                                                    {spinnerLoading
                                                        ? <div className='vh-100 flex justify-center items-center'>
                                                            <Oval
                                                                height="100"
                                                                width="100"
                                                                color='var(--ps-main)'
                                                                secondaryColor="var(--ps-main)"
                                                                ariaLabel='oval-loading'
                                                                strokeWidth={4}
                                                                strokeWidthSecondary={4}
                                                                visible={true}
                                                            />
                                                        </div>
                                                        :
                                                        <div className="col-md-12">
                                                            <div className="tab-content">

                                                                {/* ----- Active Post Tabs ----- */}
                                                                <div className={navTabs.active ? "tab-pane active show" : "tab-pane"} id="tabItem1" role="tabpanel">
                                                                    <div className="card card-preview">
                                                                        <table className="table table-orders mt-3">
                                                                            <thead className="tb-odr-head dashboardTableHead">
                                                                                <tr className="tb-odr-item">
                                                                                    <th className="tb-odr-info">
                                                                                        <span className="tb-odr-id">Date</span></th>

                                                                                    <th className="tb-odr-info">
                                                                                        <span className="tb-odr-date">Post</span></th>

                                                                                    <th className="tb-odr-amount"><span className="tb-odr-total">Likes </span></th>

                                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Shares</span></th>

                                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Comments</span></th>

                                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Actions</span></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="tb-odr-body whatSnewTable">
                                                                                {activeWhatsNew.length > 0 &&
                                                                                    activeWhatsNew.map(data => {
                                                                                        return <tr key={data.id} className="tb-odr-item">
                                                                                            <td className="tb-odr-info">
                                                                                                {moment.utc(data.start_date_time).tz(userTimeZone).format("YYYY-MM-DD")}
                                                                                                <br />   &nbsp;
                                                                                                {moment.utc(data.start_date_time).tz(userTimeZone).format("hh:mm A")}
                                                                                            </td>
                                                                                            <td className="tb-odr-info tableImageUser">
                                                                                                <div className="user-card">
                                                                                                    <div className="user-avatar user-avatar-sm">
                                                                                                        <img src={data.image_icon} alt="Post image" />
                                                                                                    </div>
                                                                                                    <div className="user-name">
                                                                                                        <span className="tb-lead text-gray">{data.title}</span>
                                                                                                        {/* {!data.is_default && <p className='mb-1 text-primary' style={{ fontSize: "13px" }} >"LIMITED TIME"</p>} */}
                                                                                                        {/* {(data.description.length > 150 && !seeMore)
                                                                                                    ? <p><small className='text-gray' dangerouslySetInnerHTML={{ __html: data.description.slice(0, 150) }} />{!seeMore && <b className='text-primary' role='button' style={{ fontSize: "12px" }} onClick={e => setSeeMore(!seeMore)}>...Read more</b>}</p>
                                                                                                    : <p><small className='text-gray' dangerouslySetInnerHTML={{ __html: data.description }} /></p>
                                                                                                } */}
                                                                                                        <p><small className='text-gray' dangerouslySetInnerHTML={{ __html: data.description }} /></p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td className="tb-odr-info">{data?.like > 0 ? data.like : 0}</td>
                                                                                            <td className="tb-odr-info">{data?.share > 0 ? data.share : 0}</td>
                                                                                            <td className="tb-odr-info">{data?.comment > 0 ? data.comment : 0}</td>
                                                                                            <td className="tb-odr-info">
                                                                                               <Link to={`/whats-new-view/${data.id}`} role='button' className="text-soft btn btn-icon">
                                                                                                    <em className="icon ni ni-eye"></em>
                                                                                                    </Link>
                                                                                                <Link to={`/whats-new/${data.id}`} role='button' className="text-soft btn btn-icon">
                                                                                                    <em className="icon ni ni-edit"></em>
                                                                                                </Link>
                                                                                                <a onClick={e => handleDeleteWhatsNew(e, data.id)} className="text-soft btn btn-icon">
                                                                                                    <em className="icon ni ni-trash-empty"></em>
                                                                                                </a>
                                                                                            </td>
                                                                                        </tr>
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>

                                                                    </div>
                                                                    {activeWhatsNew.length == 0 &&
                                                                        <div className='d-flex justify-content-center align-item-center mt-5'>
                                                                            <span>No Data Available</span>
                                                                        </div>
                                                                    }

                                                                    {/* Pagination code */}
                                                                    {/* <div className="mt-2">
                                                                <ReactPaginate
                                                                    previousLabel={"Previous"}
                                                                    nextLabel={'Next'}
                                                                    forcePage={pageNumber - 1}
                                                                    breakLabel={"..."}
                                                                    // pageCount={10}
                                                                    pageCount={activePageCount}
                                                                    marginPagesDisplayed={1}
                                                                    pageRangeDisplayed={2}
                                                                    onPageChange={handlePageChange}
                                                                    containerClassName={'pagination justify-content-center'}
                                                                    pageClassName={'page-item'}
                                                                    pageLinkClassName={'page-link'}
                                                                    previousClassName={'page-item'}
                                                                    previousLinkClassName={'page-link'}
                                                                    nextClassName={'page-item'}
                                                                    nextLinkClassName={'page-link'}
                                                                    breakClassName={"page-item"}
                                                                    breakLinkClassName={'page-link'}
                                                                    activeClassName={'active'}
                                                                />
                                                            </div> */}

                                                                </div>


                                                                {/* ----- Expired Post Tabs ----- */}
                                                                <div className={navTabs.inactive ? "tab-pane active show" : "tab-pane"} id="tabItem2" role="tabpanel">
                                                                    <div className="card card-preview">
                                                                        <table className="table table-orders mt-3">
                                                                            <thead className="tb-odr-head dashboardTableHead">
                                                                                <tr className="tb-odr-item">
                                                                                    <th className="tb-odr-info">
                                                                                        <span className="tb-odr-id">Date</span></th>

                                                                                    <th className="tb-odr-info">
                                                                                        <span className="tb-odr-date">Post</span></th>

                                                                                    <th className="tb-odr-amount"><span className="tb-odr-total">Likes </span></th>

                                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Shares</span></th>

                                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Comments</span></th>

                                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Actions</span></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="tb-odr-body whatSnewTable">
                                                                                {expiredWhatsNew.length > 0 &&
                                                                                    expiredWhatsNew.map(data => {
                                                                                        return <tr key={data.id} className="tb-odr-item">
                                                                                            <td className="tb-odr-info">
                                                                                                {moment.utc(data.start_date_time).tz(userTimeZone).format("YYYY-MM-DD")}
                                                                                                <br />   &nbsp;
                                                                                                {moment.utc(data.start_date_time).tz(userTimeZone).format("hh:mm A")}
                                                                                            </td>
                                                                                            <td className="tb-odr-info tableImageUser">
                                                                                                <div className="user-card">
                                                                                                    <div className="user-avatar user-avatar-sm">
                                                                                                        <img src={data.image_icon} alt="Post image" />
                                                                                                    </div>
                                                                                                    <div className="user-name">
                                                                                                        <span className="tb-lead text-gray">{data.title}</span>
                                                                                                        {/* {!data.is_default && <p className='mb-1 text-primary' style={{ fontSize: "13px" }} >"LIMITED TIME"</p>} */}
                                                                                                        <p><small className='text-gray' dangerouslySetInnerHTML={{ __html: data.description }} /></p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td className="tb-odr-info">{data?.like > 0 ? data.like : 0}</td>
                                                                                            <td className="tb-odr-info">{data?.share > 0 ? data.share : 0}</td>
                                                                                            <td className="tb-odr-info">{data?.comment > 0 ? data.comment : 0}</td>
                                                                                            <td className="tb-odr-info">
                                                                                            <Link to={`/whats-new-view/${data.id}`} role='button' className="text-soft btn btn-icon">
                                                                                                    <em className="icon ni ni-eye"></em>
                                                                                                    </Link>
                                                                                                <Link to={`/whats-new/${data.id}`} role='button' className="text-soft btn btn-icon">
                                                                                                    <em className="icon ni ni-edit"></em>
                                                                                                </Link>
                                                                                                <a onClick={e => handleDeleteWhatsNew(e, data.id)} className="text-soft btn btn-icon">
                                                                                                    <em className="icon ni ni-trash-empty"></em>
                                                                                                </a>
                                                                                            </td>
                                                                                        </tr>
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>

                                                                    </div>
                                                                    {expiredWhatsNew.length == 0 &&
                                                                        <div className='d-flex justify-content-center align-item-center mt-5'>
                                                                            <span>No Data Available</span>
                                                                        </div>
                                                                    }
                                                                </div>


                                                                {/* ----- Default Post Tabs ----- */}
                                                                <div className={navTabs.default ? "tab-pane active show" : "tab-pane"} id="tabItem3" role="tabpanel">
                                                                    <div className="card card-preview">
                                                                        <table className="table table-orders mt-3">
                                                                            <thead className="tb-odr-head dashboardTableHead">
                                                                                <tr className="tb-odr-item">
                                                                                    <th className="tb-odr-info">
                                                                                        <span className="tb-odr-id">Date</span></th>

                                                                                    <th className="tb-odr-info">
                                                                                        <span className="tb-odr-date">Post</span></th>

                                                                                    <th className="tb-odr-amount"><span className="tb-odr-total">Likes </span></th>

                                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Shares</span></th>

                                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Comments</span></th>

                                                                                    <th className="tb-odr-amount"><span className="tb-odr-status">Actions</span></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="tb-odr-body whatSnewTable">
                                                                                {defaultWhatsNew.length > 0 &&
                                                                                    defaultWhatsNew.map(data => {
                                                                                        return <tr key={data.id} className="tb-odr-item">
                                                                                            <td className="tb-odr-info">
                                                                                                {moment.utc(data.start_date_time).tz(userTimeZone).format("YYYY-MM-DD")}
                                                                                                <br />   &nbsp;
                                                                                                {moment.utc(data.start_date_time).tz(userTimeZone).format("hh:mm A")}
                                                                                            </td>
                                                                                            <td className="tb-odr-info tableImageUser">
                                                                                                <div className="user-card">
                                                                                                    <div className="user-avatar user-avatar-sm">
                                                                                                        <img src={data.image_icon} alt="Post image" />
                                                                                                    </div>
                                                                                                    <div className="user-name">
                                                                                                        <span className="tb-lead text-gray">{data.title}</span>
                                                                                                        {!data.is_default && <p className='mb-1 text-primary' style={{ fontSize: "13px" }} >"LIMITED TIME"</p>}
                                                                                                        {/* {(data.description.length > 150 && !seeMore)
                                                                                                    ? <p><small className='text-gray' dangerouslySetInnerHTML={{ __html: data.description.slice(0, 150) }} />{!seeMore && <b className='text-primary' role='button' style={{ fontSize: "12px" }} onClick={e => setSeeMore(!seeMore)}>...Read more</b>}</p>
                                                                                                    : <p><small className='text-gray' dangerouslySetInnerHTML={{ __html: data.description }} /></p>
                                                                                                } */}
                                                                                                        <p><small className='text-gray' dangerouslySetInnerHTML={{ __html: data.description }} /></p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td className="tb-odr-info">{data?.like > 0 ? data.like : 0}</td>
                                                                                            <td className="tb-odr-info">{data?.share > 0 ? data.share : 0}</td>
                                                                                            <td className="tb-odr-info">{data?.comment > 0 ? data.comment : 0}</td>
                                                                                            <td className="tb-odr-info">
                                                                                            <Link to={`/whats-new-view/${data.id}`} role='button' className="text-soft btn btn-icon">
                                                                                                    <em className="icon ni ni-eye"></em>
                                                                                                    </Link>
                                                                                                <Link to={`/whats-new/${data.id}`} role='button' className="text-soft btn btn-icon">
                                                                                                    <em className="icon ni ni-edit"></em>
                                                                                                </Link>
                                                                                                <a onClick={e => handleDeleteWhatsNew(e, data.id)} className="text-soft btn btn-icon">
                                                                                                    <em className="icon ni ni-trash-empty"></em>
                                                                                                </a>
                                                                                            </td>
                                                                                        </tr>
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>

                                                                    </div>
                                                                    {defaultWhatsNew.length == 0 &&
                                                                        <div className='d-flex justify-content-center align-item-center mt-5'>
                                                                            <span>No Data Available</span>
                                                                        </div>
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {calendarView === false &&
                            <div className="d-flex justify-content-between mt-2">
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={'Next'}
                                    forcePage={pageNumber - 1}
                                    breakLabel={"..."}
                                    // pageCount={10}
                                    pageCount={navTabs.active && activePageCount || navTabs.inactive && expiredPageCount || defaultPageCount}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={2}
                                    onPageChange={handlePageChange}
                                    containerClassName={'pagination justify-content-center'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                />
                            </div>
                        }

                    </div>
                </Container>
            }
        </>
    );
}

export default WhatsNew;
