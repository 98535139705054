import axios from "axios";

const API = axios.create({ baseURL: process.env.REACT_APP_BASE_URL })

// Pool Perk's CRUD API's
export const poolPerksAPI = (headers,pageNumber,search = "") => API.get(`/admin/pool-perks?page_number=${pageNumber}&search=${search}`, headers)
export const poolPerksHistoryAPI = (headers,pageNumber,search = "") => API.get(`/admin/pool-perks-history?page_number=${pageNumber}&search=${search}`, headers)
export const discountListHistoryAPI = (headers,search="") => API.get(`/admin/export-discount-history?search=${search}`, headers)

export const poolPerksArchiveAPI = (headers,pageNumber,search = "") => API.get(`/admin/pool-perks-archive?page_number=${pageNumber}&search=${search}`, headers)
export const makePoolPerksUnarchiveAPI=(headers, id) => API.post(`/admin/pool-perks-make-unarchive/${id}`,{},headers)
export const makePoolPerksArchiveAPI=(headers, id) => API.post(`/admin/pool-perks-make-archive/${id}`,{},headers)
export const discountListArchiveAPI = (headers,search="") => API.get(`/admin/export-discount-archive?search=${search}`, headers)
export const deleteArchivePerkAPI = (headers, id) => API.delete(`/admin/pool-perks-archive/delete/${id}`, headers)

export const addNewPerkAPI = (headers, formData) => API.post(`/admin/pool-perks/add`, formData, headers)
export const editewPerkAPI = (perk_id, headers, formData) => API.post(`/admin/pool-perks/update/${perk_id}`, formData, headers)
export const deletePerkAPI = (headers, id) => API.delete(`/admin/pool-perks/delete/${id}`, headers)
export const singlePerkAPI = (perk_id, headers) => API.get(`/admin/pool-perks/${perk_id}`, headers)
export const userCustomerInfoAPI = (perk_id, headers, pageNumber) => API.get(`/admin/pool-perks-used/${perk_id}?page_number=${pageNumber}`, headers)
export const userCustomerHistoryInfoAPI = (perk_id, headers, pageNumber) => API.get(`/admin/pool-perks-history-used/${perk_id}?page_number=${pageNumber}`, headers)


// export const userCustomerInfoAPI = (perk_id, pageNumber, searchPost, sortBy, orderBy, headers, limit = 10) => API.get(`/admin//pool-perks-used/${perk_id}?&page_number=${pageNumber}&sortby=${sortBy}&orderby=${orderBy}&search=${searchPost}&size=${limit}`, headers)

// export const editWhatsNewPostAPI = (post_id, formData, headers) => API.post(`/admin/whatsnew/update/${post_id}`, formData, headers)
// export const singleWhatsNewAPI = (post_id, headers) => API.get(`/admin/whatsnew/${post_id}`, headers)
