import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Oval } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Container from "../Layouts/Container";
import { Image, ImageProps } from "antd";
import { allCustomerList } from "../../API/SmsRequest";
import { sessionExpire } from "../../Scripts/Helper";
import { useState } from "react";
import { logoutAdmin } from "../../Action/authAction";
import { deletePerkAPI, poolPerksAPI } from "../../API/PoolPerkRequest";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import Select2 from "react-select2-wrapper";
import Select from "react-select";

const imageStyle = {
  background: "white",
};
const SendSmsForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.authReducer.authData);
  const authToken = useSelector((state) => state.authReducer.token);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [currentView, setCurrentView] = useState(false);

  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [poolPerkData, setPoolPerkData] = useState([]);

  const [nextPage, setNextPage] = useState("");
  const [searchCustomer, setSearchCustomer] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [orderBy, setOrderyBy] = useState("");
  const [activeWhatsNew, setActiveWhatsNew] = useState([]);
  const [activePostData, setActivePostData] = useState({});
  const [activePageCount, setActivePageCount] = useState(0);

  const [pageNumber, setPagenumber] = useState(1);

  const [customers, setCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const handlePageChange = async (d) => {
    window.scrollTo(0, 0);
    var CurrentPage = d.selected + 1;
    setPagenumber(CurrentPage);
  };


  // Custom styles for the Select component
const customStyles = {
  control: (provided) => ({
    ...provided,
    padding: '0', // Remove padding
    minHeight: '38px', // Adjust as needed
    border: '1px solid #ced4da',
    borderRadius: '4px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 8px', // Minimal padding for content
  }),
  indicatorsContainer: () => ({
    display: 'none', // This removes all indicators (dropdown arrow and clear button)
  }),
  multiValue: (provided) => ({
    ...provided,
    margin: '2px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
};


  // const handleSearchChange = (e) => {
  //   console.log(e, "eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
  //   setSearchCustomer(e.target.value);
  // };

  const handleSearchChange = (e) => {
    // If this is a Select2 search input event
    if (e && e.target && typeof e.target.value === "string") {
      setSearchCustomer(e.target.value);
    }
  };

  const handleSelectChange = (event) => {
    setShowDropdown(event.target.value === "1"); // Show dropdown if "Selected Customer" is chosen
  };

  const fetchCustomers = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        time_zone: userTimeZone,
      },
    };

    try {
      const response = await allCustomerList(config, searchCustomer);
      // console.log(response.data.data.rows, "jfhsdfhoisefoiesfmslkdfmlsdkfdf");

      const customerData = response.data.data.rows.map((customer) => ({
        id: customer.id,
        text: `${customer.full_name} - ${customer.phone}`, // Format correctly
      }));

      setCustomers((prevCustomers) => {
        // Create a map of existing customers to check for duplicates
        const existingCustomersMap = new Map(
          prevCustomers.map(customer => [customer.id, customer])
        );
        
        // Add new customers from the search results
        customerData.forEach(customer => {
          existingCustomersMap.set(customer.id, customer);
        });
        
        // Convert back to array
        return Array.from(existingCustomersMap.values());
      });
    } catch (err) {
      console.error("Error fetching customers:", err);

      if (err.code === "ERR_NETWORK") {
        toast.error("Something went wrong! Please try again later.");
      } else {
        let res = sessionExpire(err);
        toast.error(res.message);

        if (res.status) {
          dispatch(logoutAdmin());
          navigate("/login");
        }
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(searchCustomer);
    }, 1000); // Delay of 500ms

    return () => clearTimeout(timer);
  }, [searchCustomer]);

  useEffect(() => {
    fetchCustomers();
  }, [debouncedSearch]);

  

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      {user && (
        <Container>
          <div className="nk-content mt-5">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="row g-gs mt-3">
                    <div className="col-md-12">
                      <div className="row g-gs">
                        <form
                          className="row"
                          // onSubmit={addNewDiscountForm.handleSubmit}
                        >
                          <div className="col-md-6">
                             <div className="form-group"> 
                               <div className="form-control-wrap whatNewInput"> 
                                <label className="form-label">
                                  Select Customer Type
                                </label>
                                <select
                                  className="form-control form-control-lg"
                                  placeholder="Select Type Of Customer"
                                  onChange={handleSelectChange}
                                >
                                  <option value={0}>All Customers</option>
                                  <option value={1}>Selected Customer</option>
                                </select>
                                {/* {showDropdown && (
                                  <>
                                    <label className="form-label">
                                      Select Customers
                                    </label>
                                    <Select2
                                      className="form-control form-control-lg"
                                      multiple
                                      data={customers}
                                      value={selectedCustomers}
                                      // onSelect={(e) =>
                                      //   setSelectedCustomers([...e.target.selectedOptions].map((o) => o.value))
                                      // }
                                      onChange={(e) => {
                                        setSelectedCustomers([...e.target.selectedOptions].map((o) => o.value));
                                        handleSearchChange(e);
                                      }}
                                      options={{
                                        placeholder: "Search by customer's name or mobile number",
                                      }}
                                    />
                                  </>
                                )} */}

                                {/* {showDropdown && (
                                  <>
                                    <label className="form-label">
                                      Select Customers
                                    </label>
                                    <Select
                                      isMulti
                                      className="form-control"
                                      classNamePrefix="select"
                                      options={customers.map((customer) => ({
                                        value: customer.id,
                                        label: customer.text,
                                      }))}
                                      value={selectedCustomers
                                        .map((id) => {
                                          const customer = customers.find(
                                            (c) => c.id === id
                                          );
                                          return customer
                                            ? {
                                                value: id,
                                                label: customer.text,
                                              }
                                            : null;
                                        })
                                        .filter(Boolean)}
                                      onInputChange={(inputValue) => {
                                        setSearchCustomer(inputValue);
                                        return inputValue;
                                      }}
                                      onChange={(selectedOptions) => {
                                        const selectedValues = selectedOptions
                                          ? selectedOptions.map(
                                              (option) => option.value
                                            )
                                          : [];
                                        setSelectedCustomers(selectedValues);
                                      }}

                                      placeholder="Search by customer's name or mobile number"
                                    />
                                  </>
                                )} */}

                                {showDropdown && (
                                  <>
                                    <label className="form-label">
                                      Select Customers
                                    </label>
                                    <Select
                                      isMulti
                                      styles={customStyles}
                                      className="form-control"
                                      classNamePrefix="select"
                                      options={
                                        customers?.map((customer) => ({
                                          value: customer.id,
                                          label: customer.text,
                                        })) || []
                                      } // Ensure options are always an array
                                      value={selectedCustomers
                                        ?.map((id) => {
                                          const customer = customers?.find(
                                            (c) => c.id === id
                                          );
                                          return customer
                                            ? {
                                                value: id,
                                                label: customer.text,
                                              }
                                            : null;
                                        })
                                        .filter(Boolean)}
                                      onInputChange={(inputValue) => {
                                        setSearchCustomer(inputValue);
                                        return inputValue;
                                      }}
                                      onChange={(selectedOptions) => {
                                        setSelectedCustomers(
                                          selectedOptions
                                            ? selectedOptions.map(
                                                (option) => option.value
                                              )
                                            : []
                                        );
                                      }}
                                      placeholder="Search by customer's name or mobile number"
                                    />
                                  </>
                                )}
                               </div> 
                             </div>

                            <div className="form-group">
                              <div className="form-control-wrap whatNewInput">
                                <label className="form-label">
                                  Coupon Code
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-lg"
                                  placeholder="Coupon Code"
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="form-control-wrap whatNewInput">
                                <label className="form-label">Message</label>
                                <textarea
                                  type="text"
                                  className="form-control form-control-lg"
                                  placeholder="Write a message..."
                                />
                              </div>
                            </div>

                            <button
                              type="submit px-5"
                              className="btn btn-primary d-inline w-100"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default SendSmsForm;
