import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Oval } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Container from "../Layouts/Container";
import { Image, ImageProps } from "antd";
import { allCustomerList } from "../../API/customerRequest";
import { sessionExpire } from "../../Scripts/Helper";
import { useState } from "react";
import { logoutAdmin } from "../../Action/authAction";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { getAllSmsList } from "../../API/SmsRequest";

const imageStyle = {
  background: "white",
};
const SendSms = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.authReducer.authData);
  const authToken = useSelector((state) => state.authReducer.token);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [currentView, setCurrentView] = useState(false);

  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [allSmsList, setAllSmsList] = useState([]);

  const [nextPage, setNextPage] = useState("");
  const [searchCustomer, setSearchCustomer] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [orderBy, setOrderyBy] = useState("");
  const [activeWhatsNew, setActiveWhatsNew] = useState([]);
  const [activePostData, setActivePostData] = useState({});
  const [activePageCount, setActivePageCount] = useState(0);

  const [showSmsForm, setShowSmsForm] = useState(false);

  const [pageNumber, setPagenumber] = useState(1);

  const handlePageChange = async (d) => {
    window.scrollTo(0, 0);
    var CurrentPage = d.selected + 1;
    setPagenumber(CurrentPage);
  };

  const handleSearchChange = (e) => {
    setSearchCustomer(e.target.value);
  };

  let fetchAllSmsList = async () => {
    setSpinnerLoading(true);

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        time_zone: userTimeZone,
      },
    };

    await getAllSmsList(config, pageNumber,searchCustomer)
      .then((response) => {
        // console.log(response.data.data.rows, "responseeerererererrere");

        // setAllSmsList(response.data.data.rows);

        setAllSmsList(
          response ? (response.data.data.rows ? response.data.data.rows : []) : []
        );

        setActivePostData({
          count: response.data.data.count,
          currentPage: response.data.data.currentPage,
          totalPages: response.data.data.totalPages,
        });
        setActivePageCount(Math.ceil(response.data.data.count / 10));
      })
      .catch((err) => {
        console.error("Error fetching perk history:", err);

        if (err.code === "ERR_NETWORK") {
          toast.error("Something went wrong! Please try again later.");
        } else {
          let res = sessionExpire(err);
          toast.error(res.message);

          if (res.status) {
            dispatch(logoutAdmin());
            navigate("/login");
          }
        }
      });
    setSpinnerLoading(false);
  };

  useEffect(() => {
    fetchAllSmsList();
  }, [pageNumber,searchCustomer]);

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      {user && (
        <Container>
          <div className="nk-content mt-5 ">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head nk-block-head-sm mt-3">
                    <div className="nk-block-between">
                      <div className="nk-block-head-content">
                        <h4 className="nk-block-title page-title">
                          The Pool Store /<span> Sent SMS </span>
                        </h4>
                      </div>
                      <div className="d-flex">
                        <Link
                          to={"/send-sms-form"}
                          className="btn btn-primary d-md-inline-flex fRight"
                        >
                          Send SMS
                        </Link>
                      </div>
                    </div>

                    <div className="col-md-6 ms-auto text-end mt-2">
                      <div className="form-control-wrap searchBarTable">
                        <div className="form-icon form-icon-right">
                          <em className="icon ni ni-search"></em>
                        </div>
                        <input
                          type="text"
                          value={searchCustomer}
                          onChange={handleSearchChange}
                          className="form-control"
                          id="fv-email"
                          name="fv-email"
                          placeholder="Search Here..."
                        />
                      </div>
                    </div>

                    {spinnerLoading ? (
                      <div className="vh-100 flex justify-center items-center">
                        <Oval
                          height="100"
                          width="100"
                          color="var(--ps-main)"
                          secondaryColor="var(--ps-main)"
                          ariaLabel="oval-loading"
                          strokeWidth={4}
                          strokeWidthSecondary={4}
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div
                        className="card card-preview "
                        // style={{ overflowX: "scroll" }}
                      >
                        <table className="table table-orders mt-3 ">
                          <thead className="tb-odr-head dashboardTableHead">
                            <tr className="tb-odr-item">
                              <th className="tb-odr-amount">
                                <span className="tb-odr-total">
                                  Coupon Code
                                </span>
                              </th>

                              <th className="tb-odr-amount">
                                <span className="tb-odr-total">Messages</span>
                              </th>

                              <th className="tb-odr-amount">
                                <span className="tb-odr-status">Details</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="tb-odr-body allSmsList">
                            {allSmsList.map((data) => {
                              return (
                                <tr className="tb-odr-item" key={data.id}>
                                  <td className="tb-odr-info">
                                    {data.coupon_code}
                                  </td>

                                  <td className="tb-odr-info">
                                    {data.message}
                                  </td>

                                  <td className="tb-odr-info">
                                    <Link
                                      to={`/sms-details/${data.id}`}
                                      className="btn btn-primary d-md-inline"
                                    >
                                      Details
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>

                        {allSmsList?.length == 0 && (
                          <div className="d-flex justify-content-center align-item-center mt-5">
                            <span>No Data Available</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                forcePage={pageNumber - 1}
                breakLabel={"..."}
                // pageCount={10}
                pageCount={activePageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={handlePageChange}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default SendSms;
