import React, { useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Oval } from 'react-loader-spinner'
import toast, { Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux';
import { Link,useNavigate, useParams } from 'react-router-dom';
import Container from '../Layouts/Container';
import { editWhatsNewPostAPI, singleWhatsNewAPI } from '../../API/whatsNewRequest';
import moment from 'moment-timezone';
import { useDropzone } from 'react-dropzone';
import { sessionExpire } from '../../Scripts/Helper';
import { logoutAdmin } from '../../Action/authAction';

const EditWhatsNew = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const post_id = useParams().id
    const imageRef = useRef(null)
    const user = useSelector((state) => state.authReducer.authData)
    const authToken = useSelector((state) => state.authReducer.token)

    // Get the user's time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [postExpiry, setPostExpiry] = useState(false)
    const [isPostDefault, setIsPostDefault] = useState(false)
    const [isFacebook, setIsFacebook] = useState(false)
    const [isInstagram, setIsInstagram] = useState(false)
    const [currentDateTime, setCurrentDateTime] = useState("")
    const [expDateTime, setExpDateTime] = useState(moment.utc(moment().add(1, 'days')).tz(userTimeZone).format('yyyy-MM-DDTHH:mm'))
    const [postData, setPostData] = useState({})
    const [postImage, setPostImage] = useState("")

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            setPostImage(acceptedFiles[0])
            editWhatsNewForm.setFieldValue("image", URL.createObjectURL(acceptedFiles[0]))
        },
    });

    const editWhatsNewForm = useFormik({
        initialValues: {
            title: postData.title,
            description: postData.description,
            image: postData.image_icon,
            is_default: postData.is_default,
            start_date_time: postData.start_date_time,
            post_exp_time: "",
            product_url: postData.product_url,
            product_id: postData.product_id,
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            title: yup.string().required('Title is required'),
            description: yup.string().required('Description is required'),
            image: yup.string().required('Image is required'),
            // product_id: yup.string().required('Product ID is required'),
        }),
        onSubmit: async (values) => {

            // setBtnLoading(true)

            // const formData = new FormData();
            // formData.append("title", editWhatsNewForm.values.title)
            // formData.append("description", editWhatsNewForm.values.description)
            // formData.append("is_default", editWhatsNewForm.values.is_default)
            // formData.append("image", postImage)
            // formData.append("start_date_time", currentDateTime)
            // formData.append("product_url", editWhatsNewForm.values.product_url)
            // formData.append("product_id", editWhatsNewForm.values.product_id)

            // if (postExpiry) {
            //     formData.append("post_exp_time", expDateTime)
            // }


            // const config = {
            //     headers: {
            //         Authorization: `Bearer ${authToken}`,
            //         time_zone: userTimeZone,
            //     }
            // }
            // await editWhatsNewPostAPI(post_id, formData, config)
            //     .then((res) => {

            //         navigate('/whats-new')
            //         setTimeout(() => {
            //             toast.success(res.data.message)
            //         }, 100);

            //     }).catch((err) => {
            //         console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)

            //         if (err.code == "ERR_NETWORK") {
            //             toast.error("Something went wront! please try again later")
            //         }
            //         else {
            //             let res = sessionExpire(err)
            //             toast.error(res.message)

            //             if (res.status) {
            //                 dispatch(logoutAdmin())
            //                 navigate('/login')
            //             }
            //         }
            //     })

            // setBtnLoading(false)
        }
    })

    let fetchScreenData = async () => {
        setSpinnerLoading(true)
        const config = {
            headers: { Authorization: `Bearer ${authToken}` }
        }
        await singleWhatsNewAPI(post_id, config)
            .then((response) => {
                setPostData(response ? response.data ? response.data.data : {} : {})
                setPostImage(response.data.data.image_icon)
                setCurrentDateTime(moment.utc(response.data.data.start_date_time).tz(userTimeZone).format("yyyy-MM-DDTHH:mm"))
                if (response.data.data.post_exp_time) {
                    setPostExpiry(true)
                    setExpDateTime(moment.utc(response.data.data.post_exp_time).tz(userTimeZone).format("yyyy-MM-DDTHH:mm"))
                }
                if (response.data.data.is_default) {
                    setIsPostDefault(true)
                }
            }).catch((err) => {
                console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)
            })
        setSpinnerLoading(false)
    }

    useEffect(() => {
        !user && navigate('/login')
        fetchScreenData()
    }, []);



    return (
        <>
            {user &&
                <Container>
                    <Toaster position="top-right" reverseOrder={false} />
                    {spinnerLoading
                        ? <div className='vh-100 flex justify-center items-center'>
                            <Oval
                                height="100"
                                width="100"
                                color='var(--ps-main)'
                                secondaryColor="var(--ps-main)"
                                ariaLabel='oval-loading'
                                strokeWidth={4}
                                strokeWidthSecondary={4}
                                visible={true}
                            />
                        </div>
                        :
                        <div className="nk-content">
                            <div className="container-fluid mt-3">
                                <div className="nk-content-inner">
                                    <div className="nk-content-body">
                                        <div className="nk-block-head nk-block-head-sm p-0">
                                            <div className="nk-block-between">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title page-title">
                                                        <Link to={`/whats-new`}>What’s New </Link>/
                                                        <span> View Post</span>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="nk-block-head nk-block-head-sm">
                                            <div className="nk-block-between">
                                                <div className="nk-block-head-content w-100">

                                                    <div className="nk-block-head-content w-100 mt-5">
                                                        <form className="row mt-4" onSubmit={editWhatsNewForm.handleSubmit}>
                                                            <div className="row justify-center items-center w-100">
                                                                <div className="col-md-7">
                                                                    <div className="titleTogglebOX">
                                                                        <div className="user-card">
                                                                            <div className="user-avatar bg-primary">
                                                                                <img src="/images/jeffrey-blue-bg.png" />
                                                                            </div>
                                                                            <div className="user-info">
                                                                                <span className="lead-text">Pool Store</span>
                                                                            </div>
                                                                        </div>

                                                                        {/* <div className="socialMedia">
                                                                            <div className="fbToggle">
                                                                                <div className="custom-control custom-switch">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        id="customSwitch1"
                                                                                        checked={isFacebook}
                                                                                        value={isFacebook}
                                                                                        onChange={(e) => {
                                                                                            setIsFacebook(p => !p)
                                                                                            editWhatsNewForm.setFieldValue("is_facebook", !isFacebook)
                                                                                        }}
                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="customSwitch1"><img src="/images/facebook.png" /> Facebook</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="fbToggle">
                                                                                <div className="custom-control custom-switch">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        id="customSwitch2"
                                                                                        checked={isInstagram}
                                                                                        value={isInstagram}
                                                                                        onChange={(e) => {
                                                                                            setIsInstagram(!isInstagram)
                                                                                            editWhatsNewForm.setFieldValue("is_instagram", !isInstagram)
                                                                                        }}
                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="customSwitch2"><img src="/images/instagram.png" /> Instagram</label>
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
{/* 
                                                                    <div className="row mt-2">
                                                                        <div className="col-sm-12">
                                                                            <div className="form-group">
                                                                                <div className="form-control-wrap">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control no-resize"
                                                                                        id="default-textarea"
                                                                                        placeholder='Post Title'
                                                                                        name='title'
                                                                                        {...editWhatsNewForm.getFieldProps("title")}
                                                                                    />
                                                                                    {editWhatsNewForm.touched.title && editWhatsNewForm.errors.title
                                                                                        && <div className="invalid-feedback" style={{ display: "block" }}>
                                                                                            {editWhatsNewForm.errors.title}
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}

                                                                    {/* <div className="row mt-2">
                                                                        <div className="col-sm-12">
                                                                            <div className="form-group">
                                                                                <div className="form-control-wrap">
                                                                                    <CKEditor
                                                                                        editor={ClassicEditor}
                                                                                        data={editWhatsNewForm.values.description}
                                                                                        onReady={editor => {
                                                                                        }}
                                                                                        onChange={(event, editor) => {
                                                                                            const data = editor.getData();
                                                                                            editWhatsNewForm.setFieldValue("description", data)
                                                                                        }}
                                                                                    />
                                                                                    {editWhatsNewForm.touched.description && editWhatsNewForm.errors.description
                                                                                        && <div className="invalid-feedback" style={{ display: "block" }}>
                                                                                            {editWhatsNewForm.errors.description}
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}

                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <div className="imguploadandpreview">

                                                                                {/* {postImage &&
                                                                                    <ul>
                                                                                        <li>
                                                                                            <span>
                                                                                                <img src="/images/Image.png" style={{ width: "30px" }} className='me-2' /> {postImage.name ? postImage.name : "Post Image"}</span>
                                                                                            <span>
                                                                                                <em className="trashIcon icon ni ni-trash"
                                                                                                    onClick={e => {
                                                                                                        setPostImage("")
                                                                                                        editWhatsNewForm.setFieldValue("image", "")
                                                                                                    }}>
                                                                                                </em>
                                                                                            </span>
                                                                                        </li>
                                                                                    </ul>
                                                                                }
                                                                                <hr className='m-0' /> */}

                                                                                <div className="imgLeftUploadDateTimer">
                                                                                    <div className="d-flex flex-column align-items-start leftDivUpload">
                                                                                    <label className="form-label ">Title</label>
                                                                                  
                                                                                        <label className="form-label ">Schedule Date & Time</label>
                                                                                        <label className="form-label ">Default</label>
                                                                                        <label className="form-label ">Expiration Date</label>
                                                                                        {postData.product_id?
                                                                                        <label className="form-label ">Product ID</label>

                                                                                        : ''}

                                                                                        {postData.product_url?
                                                                                        <label className="form-label">Product URL</label>
                                                                                        : ''}
                                                                                         <label className="form-label ">Facebook</label>
                                                                                         <label className="form-label ">Instagram</label>

                                                                                         <label className="form-label ">Description</label>
                                                                                    </div>

                                                                                    <div className="RightDivUpload">
   
                                                                                        <div className="col-md-12 ">
                                                                                            {/* <div className="form-control-wrap whatNewInput"> */}
                                                                                            <label className="form-label"> {postData.title}</label>
                                                                                             
                                                                                            {/* </div> */}
                                                                                        </div>


                                                                                       
                                                                                       {/* {postData.start_date_time? */}
                                                                                        <div className="col-md-12 ">
                                                                                            {/* <div className="form-control-wrap whatNewInput"> */}
                                                                                            <label className="form-label"> {moment(postData.start_date_time).format("MMMM DD, yyyy")}</label>
                                                                                             
                                                                                            {/* </div> */}
                                                                                        </div>
                                                                                        {/* : '' } */}


                                                                                        {/* {postData.is_default? */}
                                                                                            <div className="col-md-12">
                                                                                                {/* <div className="discountExpireBox "> */}
                                                                                                    {/* <div className="custom-control " style={{padding:'0px'}}>  */}
                                                                                                    <label className="form-label">{postData.is_default?'Yes':'No'} </label>
                                                                                                    {/* </div>
                                                                                                </div> */}
                                                                                            </div>
                                                                                        {/* : '' } */}

                                                                                        {postData.post_exp_time?
                                                                                            <div className="col-md-12 ">
                                                                                                       <label className="form-label">{moment(postData.post_exp_time).format("MMMM DD, yyyy")}</label> 
                                                                                            </div>
                                                                                        : '' }


                                                                                    {postData.product_id?
                                                                                        <div className="col-md-12 ">
                                                                                            <label className="form-label">
                                                                                            {postData.product_id}
                                                                                               
                                                                                            </label>
                                                                                        </div>
                                                                                        : '' }


                                                                                    {postData.product_url?
                                                                                        <div className="col-md-12 ">
                                                                                            <div className="form-control-wrap whatNewInput">
                                                                                              {postData.product_url}
                                                                                            </div>
                                                                                        </div>
                                                                                    :''}

                                                                                        <div className="col-md-12 ">
                                                                                            <div className="form-control-wrap whatNewInput">
                                                                                              {postData.is_facebook?'Yes':'No'}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-12 ">
                                                                                            <div className="form-control-wrap whatNewInput">
                                                                                              {postData.is_instagram?'Yes':'No'}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-12 ">
                                                                                            {/* <div className="form-control-wrap whatNewInput"> */}
                                                                                            <label  className="form-label" dangerouslySetInnerHTML={{ __html: postData.description }} ></label> 

                                                                                             
                                                                                            {/* </div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-5">
                                                                    <h4>Preview</h4>
                                                                    <div className="f-card">
                                                                        <div className="header">
                                                                            {/* <div className="options">
                                                                                <div className="dropdown">
                                                                                    <a className="text-soft dropdown-toggle btn btn-icon btn-trigger" aria-expanded="false">
                                                                                        <em className="icon ni ni-more-h"></em>
                                                                                    </a>
                                                                                </div>
                                                                            </div> */}
                                                                            <img className="co-logo" src="/images/jeffrey-png.png" />
                                                                            <div className="co-name"><b>{editWhatsNewForm.values.title ? editWhatsNewForm.values.title.length > 25 ? editWhatsNewForm.values.title.slice(0, 28) + "..." : editWhatsNewForm.values.title : "What’s new post title will be here"}</b></div>
                                                                            <div className="time">
                                                                                {currentDateTime ? moment(currentDateTime).format("MMMM DD, yyyy") : "August 24, 2023"}
                                                                            </div>
                                                                            {/* <div className="time">5 minutes ago · <i className="fa fa-globe"></i></div> */}
                                                                        </div>
                                                                        <div className="content">
                                                                            {
                                                                                editWhatsNewForm.values.description ? editWhatsNewForm.values.description.length > 40 ?
                                                                                    <div dangerouslySetInnerHTML={{ __html: editWhatsNewForm.values.description.slice(0, 35) + "..." }} /> :
                                                                                    <div dangerouslySetInnerHTML={{ __html: editWhatsNewForm.values.description }} /> :
                                                                                    "Your what's new post description will be preview here"
                                                                            }
                                                                        </div>

                                                                        {postImage
                                                                            ? <div className='d-flex justify-content-center align-item-center'>
                                                                                <img src={editWhatsNewForm.values.image} alt="Screen Image" style={{ width: "250px" }} />
                                                                            </div>
                                                                            : <div className="my-2"  {...getRootProps()}>
                                                                                <input {...getInputProps()} />
                                                                                {isDragActive
                                                                                    ?
                                                                                    <div className='d-flex justify-content-center align-items-center border border-3 border-dashed rounded-3' style={{ width: "350px", height: "200px", backgroundColor: "whitesmoke" }}>
                                                                                        <h6>Drop the image here...</h6>
                                                                                    </div>
                                                                                    : <div className='d-flex justify-content-center align-items-center border border-3 border-dashed rounded-3 flex-column p-2' style={{ height: "200px" }}>
                                                                                        <img className="imgUploadIcon" src="/images/Image.png" alt="user-avatar" />
                                                                                        <div className="btn uploadTxt">
                                                                                            <em className="icon ni ni-upload" /> Upload Image
                                                                                        </div>
                                                                                        <div className="dz-message" data-dz-message="">
                                                                                            <span className="dz-message-or">Select or drag & drop onboarding screen image here.</span><br />
                                                                                            <span className="dz-message-text">File Format jpeg, png Accepted Size 600x600</span>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {editWhatsNewForm.touched.image && editWhatsNewForm.errors.image
                                                                                    ? <div className="invalid-feedback" style={{ display: "block" }}>
                                                                                        {editWhatsNewForm.errors.image}
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                            </div>
                                                                        }


                                                                    </div>

                                                                    
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Container>
            }
        </>
    );
}

export default EditWhatsNew;
