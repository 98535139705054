import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner'
import Container from '../Layouts/Container';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { logoutAdmin } from '../../Action/authAction';
import { sessionExpire } from '../../Scripts/Helper';
import { customerDetailAndOrdersAPI } from '../../API/customerRequest';

const CustomerDetail = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const customerId = useParams().id

    const user = useSelector((state) => state.authReducer.authData)
    const authToken = useSelector((state) => state.authReducer.token)

    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [customerData, setCustomerData] = useState({})
    const [orderlist, setOrderList] = useState([])

    let fetchCustomerDetail = async () => {
        setSpinnerLoading(true)
        const config = {
            headers: { Authorization: `Bearer ${authToken}` }
        }
        await customerDetailAndOrdersAPI(config, customerId)
            .then((response) => {

                setCustomerData(response ? response.data ? response.data.data.customer : {} : {})
                setOrderList(response ? response.data ? response.data.data.orders : [] : [])

            }).catch((err) => {
                console.log("🚀 ~ file: CustomerDatabase.jsx:31 ~ .then ~ err:", err)

                if (err.code == "ERR_NETWORK") {
                    toast.error("Something went wront! please try again later")
                }
                else {
                    let res = sessionExpire(err)
                    console.log("🚀 ~ file: CustomerDatabase.jsx:44 ~ .then ~ res:", res)
                    toast.error(res.message)

                    if (res.status) {
                        dispatch(logoutAdmin())
                        navigate('/login')
                    }
                }
            })
        setSpinnerLoading(false)
    }

    useEffect(() => {
        !user && navigate('/login')
        fetchCustomerDetail()
    }, [])

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {user &&
                <Container>
                    <div className="nk-content mt-5 ">
                        <div className="container-fluid">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">

                                    <div className="nk-block-head nk-block-head-sm mt-3">
                                        <div className="nk-block-between">
                                            <div className="nk-block-head-content">
                                                <h4 className="nk-block-title page-title">The Pool Store /
                                                    <span> Customer Database</span>
                                                </h4>
                                            </div>{/* .nk-block-head-content */}
                                        </div>{/* .nk-block-between */}
                                    </div>{/* .nk-block-head */}

                                    {/* <div className="nk-block-head nk-block-head-sm mt-3">
                                        <div className="nk-block-between">
                                            <div className="nk-block-head-content">
                                                <div className="idTracking">
                                                    <span><b>User ID</b> #743648</span>
                                                    <span><b>Customer Since </b>12 Sept 2022 - 12:55 pm</span>
                                                    <span><b>Tracking ID</b> 9348fjr73
                                                        <a href=""><em className="icon ni ni-copy"></em></a>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <a className="btn btn-dark d-md-inline-flex fRight mr-3" href=''>Edit Customer</a>
                                                <a className="btn btn-danger d-md-inline-flex fRight" href=''>Suspend Customer</a>
                                            </div>
                                        </div>
                                    </div> */}


                                    <div className="nk-block-head nk-block-head-sm mt-3">
                                        <div className="nk-block-between row">
                                            <div className="col-md-6">
                                                <ul className="nk-support boxCustomer">
                                                    <li className="nk-support-item flexWrap">
                                                        <div className="userAvatarcustmerPage">
                                                            <em className="icon ni ni-user-alt-fill f35Size"></em>
                                                        </div>
                                                        <div className="nk-support-content">
                                                            <div className="title"><span>{customerData.first_name ? customerData.first_name : "Not Updated"} {customerData.last_name}</span>
                                                                <span className="badge bg-light ms-1 activeBadge">Active</span>
                                                            </div>
                                                            <p className='mt-0'>Last order <b>12 sept 2023</b></p>
                                                        </div>
                                                        <div className="row d-flex mt-5 w100">
                                                            <div className="col-md-6">
                                                                <p>Phone <br /><span><b>{customerData.phone ? customerData.phone : "Not Updated"}</b></span></p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>Email <br /> <span><b>{customerData.email ? customerData.email : "Not Updated"}</b></span></p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6">
                                                <ul className="nk-support boxCustomer">
                                                    <li className="nk-support-item flexWrap">
                                                        <div className="userAvatarcustmerPage">
                                                            <em className="icon ni ni-user-alt-fill f35Size"></em>
                                                        </div>
                                                        <div className="nk-support-content">
                                                            <div className="title"><span>Default Address</span>
                                                                <span className={customerData?.email_marketing_consent?.state == 'subscribed' ? "badge rounded-pill badge-dim bg-outline-success" : "badge rounded-pill badge-dim bg-secondary"}>{customerData?.email_marketing_consent?.state}</span>
                                                            </div>
                                                            <p className='mt-0'>Last order <b>12 sept 2023</b></p>
                                                        </div>
                                                        <div className="row d-flex mt-5 w100">
                                                            <div className="col-md-6">
                                                                <p>{customerData?.default_address?.name ? customerData?.default_address?.name : `${customerData?.first_name} ${customerData?.last_name}`} <br /><span><b>{customerData?.default_address?.company} <br /> {customerData?.default_address?.address1 ? customerData?.default_address?.address1 : "Not Updated"}</b></span></p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p><span><b>{customerData?.default_address?.city} {customerData?.default_address?.province} <br /> {customerData?.default_address?.zip} <br />{customerData?.default_address?.phone}</b></span></p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>{/* .nk-block-between */}
                                    </div>{/* .nk-block-head */}



                                    <div className="nk-block">
                                        <div className="row g-gs">
                                            <div className="col-md-7">
                                                <div className="circleChartFlex align-items-center">
                                                    <div className="">
                                                        <h6>{customerData.first_name}'s Orders</h6>
                                                    </div>

                                                    <div className="d-flex">
                                                        <a href="" className="thiryday">Last 30 days</a>
                                                        <div className="drodown">
                                                            <a href="#" className="dropdown-toggle btn btn-outline-light btn-white filterBnt" data-bs-toggle="dropdown" aria-expanded="false">Filter
                                                                <em className="icon ni ni-filter-alt"></em></a>
                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                <ul className="link-list-opt no-bdr">
                                                                    <li><a href="#"><span>- Price Low to high</span></a></li>
                                                                    <li><a href="#"><span>- Price High to Low</span></a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="drodown">
                                                            <a href="#" className="dropdown-toggle btn btn-outline-light btn-white filterBnt" data-bs-toggle="dropdown" aria-expanded="false">Bulk Action
                                                                <em className="icon ni ni-downward-ios"></em></a>
                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                <ul className="link-list-opt no-bdr">
                                                                    <li><a href="#"><span>- Price Low to high</span></a></li>
                                                                    <li><a href="#"><span>- Price High to Low</span></a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="form-control-wrap searchBarTable">
                                                    <div className="form-icon form-icon-right">
                                                        <em className="icon ni ni-search"></em></div>
                                                    <input type="text" className="form-control" placeholder='Search Here...' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    <div className="nk-block">
                                        <table className="table table-orders mt-3">
                                            <thead className="tb-odr-head dashboardTableHead">
                                                <tr className="tb-odr-item">
                                                    <th className="tb-odr-info"><span className="tb-odr-date">Order</span></th>
                                                    <th className="tb-odr-info"><span className="tb-odr-date">Order date</span></th>
                                                    <th className="tb-odr-info"><span className="tb-odr-date">Customer</span></th>
                                                    <th className="tb-odr-info"><span className="tb-odr-date">Channel</span></th>
                                                    <th className="tb-odr-info"><span className="tb-odr-date">Total</span></th>
                                                    <th className="tb-odr-info"> <span className="tb-odr-date">Payment status</span></th>
                                                    <th className="tb-odr-info"> <span className="tb-odr-date">Fulfillment status</span></th>
                                                    <th className="tb-odr-info"> <span className="tb-odr-date">Items</span></th>
                                                    <th className="tb-odr-info"> <span className="tb-odr-date">Delivery status</span></th>
                                                </tr>
                                            </thead>
                                            <tbody className="tb-odr-body whatSnewTable">
                                                {console.log("🚀 ~ file: CustomerDetail.jsx:215 ~ CustomerDetail ~ orderlist:", orderlist)}
                                                {orderlist.length > 0 &&
                                                    orderlist.map(data => {
                                                        return <tr key={data.id} className="tb-odr-item customerDetailTable">
                                                            <td className="tb-odr-info">{data?.order_number}</td>
                                                            <td className="tb-odr-info">{moment(data?.created_at).format("MMM DD, YYYY")}</td>
                                                            <td className="tb-odr-info">{data?.customer?.first_name} {data?.customer?.last_name}</td>
                                                            <td className="tb-odr-info">{data?.source_name == 'web' ? "Online Store" : data?.source_name}</td>
                                                            <td className="tb-odr-info">${data?.current_total_price}</td>
                                                            <td className="tb-odr-info"><em className="icon ni ni-circle-fill greeColor"></em> {data?.financial_status}</td>
                                                            <td className="tb-odr-info">
                                                                <em className="icon ni ni-truck bluetruck"> </em>{data.fulfillment_status !== null ? "Fulfilled" : "Unfulfilled"}</td>
                                                            <td className="tb-odr-info">{data.line_items.reduce((total, lineItem) => total + lineItem.quantity, 0)}</td>
                                                            <td className="tb-odr-info">
                                                                <div className="dropdown">
                                                                    <a className="text-soft dropdown-toggle btn btn-icon btn-trigger foucsBtn" data-bs-toggle="dropdown">
                                                                        {data.fulfillments.length > 0 ? data.fulfillments[0].shipment_status : "Not Updated"}
                                                                        {/* <em className="icon ni ni-downward-ios"></em> */}
                                                                    </a>
                                                                    <div className="dropdown-menu dropdown-menu-end dropdown-menu-xs">
                                                                        <ul className="link-list-plain">
                                                                            <li><a href="#">In Progress</a></li>
                                                                            <li><a href="#">Completed</a></li>
                                                                            <li><a href="#">Cancelled</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>





                                </div>
                            </div>
                        </div>
                    </div>


                </Container>
            }
        </>
    );
}

export default CustomerDetail;
