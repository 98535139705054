import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Oval } from 'react-loader-spinner'
import toast, { Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Container from '../Layouts/Container';
import { addWhatsNewPostAPI, deleteWhatsNewPostAPI, editWhatsNewPostAPI, whatsNewAPI } from '../../API/whatsNewRequest';
import moment from 'moment-timezone';
import { useDropzone } from 'react-dropzone';
import { sessionExpire } from '../../Scripts/Helper';
import { logoutAdmin } from '../../Action/authAction';

const WhatsNew = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const imageRef = useRef(null)
    const user = useSelector((state) => state.authReducer.authData)
    const authToken = useSelector((state) => state.authReducer.token)

    // Get the user's time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [btnLoading, setBtnLoading] = useState(false)
    const [postExpiry, setPostExpiry] = useState(false)
    const [isPostDefault, setIsPostDefault] = useState(false)
    const [isFacebook, setIsFacebook] = useState(false)
    const [isInstagram, setIsInstagram] = useState(false)
    const [currentDateTime, setCurrentDateTime] = useState(moment.utc(moment()).tz(userTimeZone).format('yyyy-MM-DDTHH:mm'))
    const [expDateTime, setExpDateTime] = useState(moment.utc(moment().add(1, 'days')).tz(userTimeZone).format('yyyy-MM-DDTHH:mm'))
    const [postImage, setPostImage] = useState("")
    const [socialMediaDesc, setSocialMediaDesc] = useState("")

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            setPostImage(acceptedFiles[0])
            addWhatsNewForm.setFieldValue("image", acceptedFiles[0])
        },
    });

    useEffect(() => {
        !user && navigate('/login')
    }, []);


    const addWhatsNewForm = useFormik({
        initialValues: {
            title: "",
            description: "",
            image: "",
            is_default: "",
            is_facebook: "",
            is_instagram: "",
            start_date_time: "",
            post_exp_time: "",
            product_url: "",
            product_id: "",
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            title: yup.string().required('Title is required'),
            description: yup.string().required('Description is required'),
            image: yup.string().required('Image is required'),
            // start_date_time: yup.string().required('Post start date & time is required'),
            // product_id: yup.string().required('Product ID is required'),
        }),
        onSubmit: async (values) => {

            setBtnLoading(true)

            const formData = new FormData();
            formData.append("title", addWhatsNewForm.values.title)
            formData.append("description", addWhatsNewForm.values.description)
            formData.append("is_default", addWhatsNewForm.values.is_default)
            formData.append("image", postImage)
            formData.append("start_date_time", currentDateTime)
            formData.append("is_facebook", isFacebook)
            formData.append("is_instagram", isInstagram)
            formData.append("socialMediaDesc", socialMediaDesc)
            formData.append("product_url", addWhatsNewForm.values.product_url)
            formData.append("product_id", addWhatsNewForm.values.product_id)

            if (postExpiry) {
                formData.append("post_exp_time", expDateTime)
            }

            const config = {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    time_zone: userTimeZone,
                }
            }
            await addWhatsNewPostAPI(formData, config)
                .then((res) => {

                    navigate('/whats-new')
                    setTimeout(() => {
                        toast.success(res.data.message)
                    }, 100);

                }).catch((err) => {
                    console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)

                    if (err.code == "ERR_NETWORK") {
                        toast.error("Something went wront! please try again later")
                    }
                    else {
                        let res = sessionExpire(err)
                        toast.error(res.message)

                        if (res.status) {
                            dispatch(logoutAdmin())
                            navigate('/login')
                        }
                    }
                })

            setBtnLoading(false)
        }
    })

    // const editWhatsNewForm = useFormik({
    //     initialValues: {
    //         post_id: "",
    //         title: "",
    //         description: "",
    //         image: "",
    //         is_default: "",
    //     },
    //     enableReinitialize: true,
    //     validationSchema: yup.object({
    //         title: yup.string().required('Post title is required'),
    //         description: yup.string().required('Post description is required'),
    //         image: yup.string().required('Post image is required'),
    //         is_default: yup.string().required('Post type is required'),
    //     }),
    //     onSubmit: async (values, { resetForm }) => {

    //         setBtnLoading(true)

    //         const formData = new FormData();
    //         formData.append("title", editWhatsNewForm.getFieldProps('title').value)
    //         formData.append("description", editWhatsNewForm.getFieldProps('description').value)
    //         formData.append("is_default", editWhatsNewForm.getFieldProps('is_default').value)
    //         formData.append("image", postImage);

    //         const config = {
    //             headers: { Authorization: `Bearer ${authToken}` }
    //         };
    //         await editWhatsNewPostAPI(values.post_id, formData, config)
    //             .then((res) => {
    //                 imageRef.current.value = ''
    //                 resetForm({ values: "" })
    //                 setPostImage("")
    //                 setPostImage("")

    //                 toast.success(res.data.message)
    //                 setEditWhatsNewModal(false)

    //             }).catch((err) => {
    //                 console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err)
    //                 if (err.code == "ERR_NETWORK") {
    //                     toast.error("Something went wront! please try again later")
    //                 }
    //                 else {
    //                     toast.error(err.response.data.message)
    //                 }
    //             })
    //         setBtnLoading(false)
    //     }
    // })


    return (
        <>
            {user &&
                <Container>
                    <Toaster position="top-right" reverseOrder={false} />

                    <div className="nk-content">
                        <div className="container-fluid">
                            <div className="nk-content-inner">
                                <div className="nk-content-body">
                                    <div className="nk-block-head nk-block-head-sm p-0">
                                        <div className="nk-block-between">
                                            <div className="nk-block-head-content">
                                                <h4 className="nk-block-title page-title">What’s New /
                                                    <span> Add New-Post</span>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="nk-block-head nk-block-head-sm">
                                        <div className="nk-block-between">
                                            <div className="nk-block-head-content w-100">
                                                {/* <div className="row items-center">
                                                    <div className="col-md-4">
                                                        <h4>Create New Post</h4>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <ul className="nk-block-tools g-3 justify-content-end">
                                                            <li>
                                                                <a href="#" className="btn btn-outline-light slectDraftBnt">Schedule Post</a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="btn btn-outline-light slectDraftBnt">Restore Default Settings</a>
                                                            </li>
                                                            <li className="nk-block-tools-opt">
                                                                <a href="#" className="btn btn-primary lightBlue d-md-inline-flex">Save & Publish</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div> */}

                                                <div className="nk-block-head-content w-100 mt-5">
                                                    <form className="row mt-4" onSubmit={addWhatsNewForm.handleSubmit}>
                                                        <div className="row justify-center items-center w-100">
                                                            <div className="col-md-7">
                                                                <div className="titleTogglebOX">
                                                                    <div className="user-card">
                                                                        <div className="user-avatar bg-primary">
                                                                            <img src="images/jeffrey-blue-bg.png" />
                                                                        </div>
                                                                        <div className="user-info">
                                                                            <span className="lead-text">Pool Store</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="socialMedia">
                                                                        <div className="fbToggle">
                                                                            <div className="custom-control custom-switch">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    id="customSwitch1"
                                                                                    checked={isFacebook}
                                                                                    value={isFacebook}
                                                                                    onChange={(e) => {
                                                                                        setIsFacebook(p => !p)
                                                                                        addWhatsNewForm.setFieldValue("is_facebook", !isFacebook)
                                                                                    }}
                                                                                />
                                                                                <label className="custom-control-label" htmlFor="customSwitch1"><img src="images/facebook.png" /> Facebook</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="fbToggle">
                                                                            <div className="custom-control custom-switch">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    id="customSwitch2"
                                                                                    checked={isInstagram}
                                                                                    value={isInstagram}
                                                                                    onChange={(e) => {
                                                                                        setIsInstagram(!isInstagram)
                                                                                        addWhatsNewForm.setFieldValue("is_instagram", !isInstagram)
                                                                                    }}
                                                                                />
                                                                                <label className="custom-control-label" htmlFor="customSwitch2"><img src="images/instagram.png" /> Instagram</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mt-2">
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <div className="form-control-wrap">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control no-resize"
                                                                                    id="default-textarea"
                                                                                    placeholder='Post Title'
                                                                                    name='title'
                                                                                    {...addWhatsNewForm.getFieldProps("title")}
                                                                                />
                                                                                {addWhatsNewForm.touched.title && addWhatsNewForm.errors.title
                                                                                    && <div className="invalid-feedback" style={{ display: "block" }}>
                                                                                        {addWhatsNewForm.errors.title}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mt-2">
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <div className="form-control-wrap">
                                                                                <CKEditor
                                                                                    editor={ClassicEditor}
                                                                                    data={addWhatsNewForm.values.description}
                                                                                    onReady={editor => {
                                                                                    }}
                                                                                    onChange={(event, editor) => {
                                                                                        const data = editor.getData();
                                                                                        // console.log("event: " + event.target.value)const doc = new DOMParser().parseFromString(htmlString, "text/html");                   
                                                                                        const doc = new DOMParser().parseFromString(data, "text/html");
                                                                                        const cleanText = doc.body.textContent || "";
                                                                                        setSocialMediaDesc(cleanText)

                                                                                        addWhatsNewForm.setFieldValue("description", data)
                                                                                    }}
                                                                                />
                                                                                {addWhatsNewForm.touched.description && addWhatsNewForm.errors.description
                                                                                    && <div className="invalid-feedback" style={{ display: "block" }}>
                                                                                        {addWhatsNewForm.errors.description}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="imguploadandpreview">

                                                                            {postImage &&
                                                                                <ul>
                                                                                    <li>
                                                                                        <span>
                                                                                            <img src="images/Image.png" style={{ width: "30px" }} className='me-2' /> {postImage.name}</span>
                                                                                        <span>
                                                                                            {/* <a href='' className="trashIcon">
                                                                                        </a> */}
                                                                                            <em className="trashIcon icon ni ni-trash"
                                                                                                onClick={e => {
                                                                                                    setPostImage("")
                                                                                                    addWhatsNewForm.setFieldValue("image", "")
                                                                                                }}></em>
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                            }
                                                                            <hr className='m-0' />

                                                                            <div className="imgLeftUploadDateTimer">
                                                                                <div className="d-flex flex-column align-items-start leftDivUpload">
                                                                                    {/* <label className="upload-area">
                                                                                    <input type="file" />
                                                                                    <span className="upload-button">
                                                                                        <em className="icon ni ni-upload-cloud"></em>
                                                                                    </span>
                                                                                </label> */}
                                                                                    <label className="form-label mt-2">Schedule Date & Time</label>
                                                                                    <label className="form-label mt-2">Default</label>
                                                                                    <label className="form-label mt-2">Expiration Date</label>
                                                                                    {postExpiry &&
                                                                                        <label className="form-label mt-2">Expiration Date & Time</label>
                                                                                    }
                                                                                    <label className="form-label mt-2">Product ID</label>
                                                                                    <label className="form-label mt-2">Product URL</label>
                                                                                    {/* <div className="drodown">
                                                                                        <a href="#" className="dropdown-toggle btn seetingIonc" data-bs-toggle="dropdown">
                                                                                            <em className="icon ni ni-setting-alt-fill"></em>
                                                                                        </a>
                                                                                        <div className="dropdown-menu dropdown-menu-end">
                                                                                            <ul className="link-list-opt no-bdr">
                                                                                                <li><a href="#"><span><em className="icon ni ni-bell-fill"></em> Notify members</span></a></li>
                                                                                                <li><a href="#"><span><em className="icon ni ni-star-fill"></em> Topics</span></a></li>
                                                                                                <li><a href="#"><span><em className="icon ni ni-lock-fill"></em> Change to Private</span></a></li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div> */}
                                                                                </div>

                                                                                <div className="RightDivUpload">
                                                                                    {/* <div className="form-group">
                                                                                        <div className="form-control-wrap has-timepicker">
                                                                                            <input type="text" className="form-control form-control-xl form-control-outlined time-picker" id="outlined-time-picker" />
                                                                                            <label className="form-label-outlined" for="outlined-time-picker"><em className="icon ni ni-clock"></em> Chose Date & Time</label>
                                                                                        </div>
                                                                                    </div> */}

                                                                                    <div className="col-md-12 mt-0">
                                                                                        <div className="form-control-wrap whatNewInput">
                                                                                            <input
                                                                                                type="datetime-local"
                                                                                                min={moment.utc(moment().subtract(5, 'hours')).tz(userTimeZone).format('yyyy-MM-DDTHH:mm')}
                                                                                                className="form-control form-control-xl form-control-outlined"
                                                                                                id="outlined-date-picker"
                                                                                                name='start_date_time'
                                                                                                value={currentDateTime}
                                                                                                onChange={e => {
                                                                                                    // moment.utc(moment()).tz(userTimeZone).format('yyyy-MM-DDTHH:mm')
                                                                                                    addWhatsNewForm.setFieldValue("start_date_time", e.target.value)
                                                                                                    setCurrentDateTime(e.target.value)
                                                                                                    setPostExpiry(false)
                                                                                                    setExpDateTime("")
                                                                                                    // setExpDateTime(moment.utc(moment(currentDateTime).add(1, 'days')).tz(userTimeZone).format('yyyy-MM-DDTHH:mm'))
                                                                                                }}
                                                                                            />
                                                                                            {addWhatsNewForm.touched.start_date_time && addWhatsNewForm.errors.start_date_time
                                                                                                && <div className="invalid-feedback" style={{ display: "block" }}>
                                                                                                    {addWhatsNewForm.errors.start_date_time}
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-md-12 mt-2">
                                                                                        <div className="discountExpireBox mb-3">
                                                                                            <div className="custom-control custom-switch">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="custom-control-input"
                                                                                                    id="customSwitch3"
                                                                                                    checked={isPostDefault}
                                                                                                    value={isPostDefault}
                                                                                                    onChange={(e) => {
                                                                                                        setIsPostDefault(!isPostDefault)
                                                                                                        addWhatsNewForm.setFieldValue("is_default", !isPostDefault)
                                                                                                        setPostExpiry(false)
                                                                                                    }}
                                                                                                />
                                                                                                <label className="custom-control-label" htmlFor="customSwitch3">Make Default </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="discountExpireBox">
                                                                                            <div className="custom-control custom-switch">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="custom-control-input"
                                                                                                    id="customSwitch4"
                                                                                                    checked={postExpiry}
                                                                                                    value={postExpiry}
                                                                                                    onChange={(e) => {
                                                                                                        setPostExpiry(!postExpiry)
                                                                                                        setIsPostDefault(false)
                                                                                                        if (expDateTime == "") {
                                                                                                            setExpDateTime(moment.utc(moment(currentDateTime).add(1, 'days')).tz(userTimeZone).format('yyyy-MM-DDTHH:mm'))
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                                <label className="custom-control-label" htmlFor="customSwitch4">Add Expiration Date </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    {postExpiry &&
                                                                                        <>
                                                                                            <div className="col-md-12 mt-0">
                                                                                                <div className="form-control-wrap whatNewInput">
                                                                                                    <input
                                                                                                        type="datetime-local"
                                                                                                        min={moment.utc(moment(currentDateTime).add(1, 'days')).tz(userTimeZone).format('yyyy-MM-DDTHH:mm')}
                                                                                                        className="form-control form-control-xl form-control-outlined date-picker"
                                                                                                        id="outlined-date-picker"
                                                                                                        name='post_exp_time'
                                                                                                        value={expDateTime}
                                                                                                        onChange={e => {
                                                                                                            setExpDateTime(e.target.value)
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>


                                                                                        </>
                                                                                    }

                                                                                    <div className="col-md-12 mt-0">
                                                                                        <div className="form-control-wrap whatNewInput">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control no-resize px-1 mt-1"
                                                                                                id="default-textarea"
                                                                                                placeholder='Product ID'
                                                                                                name='product_id'
                                                                                                {...addWhatsNewForm.getFieldProps("product_id")}
                                                                                            />
                                                                                            {/* {addWhatsNewForm.touched.product_id && addWhatsNewForm.errors.product_id
                                                                                                && <div className="invalid-feedback" style={{ display: "block" }}>
                                                                                                    {addWhatsNewForm.errors.product_id}
                                                                                                </div>
                                                                                            } */}
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-md-12 mt-0">
                                                                                        <div className="form-control-wrap whatNewInput">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control no-resize px-1 mt-1"
                                                                                                id="default-textarea"
                                                                                                placeholder='Product URL'
                                                                                                name='product_url'
                                                                                                {...addWhatsNewForm.getFieldProps("product_url")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>


                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>


                                                            <div className="col-md-5">
                                                                <h4>Preview</h4>
                                                                <div className="f-card">
                                                                    <div className="header">
                                                                        {/* <div className="options">
                                                                            <div className="dropdown">
                                                                                <a className="text-soft dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    <em className="icon ni ni-more-h"></em></a>
                                                                                <div className="dropdown-menu dropdown-menu-end dropdown-menu-xs">
                                                                                    <ul className="link-list-plain">
                                                                                        <li><a href="#">Edit</a></li>
                                                                                        <li><a href="#">Delete</a></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                        <img className="co-logo" src="images/jeffrey-png.png" />
                                                                        <div className="co-name"><b>{addWhatsNewForm.values.title ? addWhatsNewForm.values.title.length > 25 ? addWhatsNewForm.values.title.slice(0, 28) + "..." : addWhatsNewForm.values.title : "What’s new post title will be here"}</b></div>
                                                                        <div className="time">
                                                                            {currentDateTime ? moment(currentDateTime).format("MMMM DD, yyyy") : "August 24, 2023"}
                                                                        </div>
                                                                        {/* <div className="time">5 minutes ago · <i className="fa fa-globe"></i></div> */}
                                                                    </div>
                                                                    <div className="content">
                                                                        {
                                                                            addWhatsNewForm.values.description ? addWhatsNewForm.values.description.length > 40 ?
                                                                                <div dangerouslySetInnerHTML={{ __html: addWhatsNewForm.values.description.slice(0, 35) + "..." }} /> :
                                                                                <div dangerouslySetInnerHTML={{ __html: addWhatsNewForm.values.description }} /> :
                                                                                "Your what's new post description will be preview here"
                                                                        }
                                                                    </div>

                                                                    {postImage
                                                                        ? <div className='d-flex justify-content-center align-item-center'>
                                                                            <img src={URL.createObjectURL(postImage)} alt="Screen Image" style={{ width: "250px" }} />
                                                                        </div>
                                                                        : <div className="my-2"  {...getRootProps()}>
                                                                            <input {...getInputProps()} />
                                                                            {isDragActive
                                                                                ?
                                                                                <div className='d-flex justify-content-center align-items-center border border-3 border-dashed rounded-3' style={{ width: "350px", height: "200px", backgroundColor: "whitesmoke" }}>
                                                                                    <h6>Drop the image here...</h6>
                                                                                </div>
                                                                                : <div className='d-flex justify-content-center align-items-center border border-3 border-dashed rounded-3 flex-column p-2' style={{ height: "200px" }}>
                                                                                    <img className="imgUploadIcon" src="images/Image.png" alt="user-avatar" />
                                                                                    <div className="btn uploadTxt">
                                                                                        <em className="icon ni ni-upload" /> Upload Image
                                                                                    </div>
                                                                                    <div className="dz-message" data-dz-message="">
                                                                                        <span className="dz-message-or">Select or drag & drop onboarding screen image here.</span><br />
                                                                                        <span className="dz-message-text">Maximum File Size 1000x800</span>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {addWhatsNewForm.touched.image && addWhatsNewForm.errors.image
                                                                                ? <div className="invalid-feedback" style={{ display: "block" }}>
                                                                                    {addWhatsNewForm.errors.image}
                                                                                </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    }

                                                                    {/* <div className="social">
                                                                        <div className="social-content"></div>
                                                                        <div className="social-buttons">
                                                                            <span><a href=""><i className="fa fa-thumbs-up"></i> Like</a></span>
                                                                            <span><a href=""><i className="fa fa-comment"></i> Comment</a></span>
                                                                            <span><a href=""><i className="fa fa-share"></i> Share</a></span></div>
                                                                    </div> */}
                                                                </div>

                                                                {btnLoading
                                                                    ? <div className='flex justify-center items-center mt-3'>
                                                                        <Oval
                                                                            height="30"
                                                                            width="30"
                                                                            color='var(--ps-main)'
                                                                            secondaryColor="var(--ps-main)"
                                                                            ariaLabel='oval-loading'
                                                                            strokeWidth={4}
                                                                            strokeWidthSecondary={4}
                                                                            visible={true}
                                                                        />
                                                                    </div>
                                                                    : <button type="submit" className="btn btn-primary lightBlue d-inline w-100 mt-3">Upload Post</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>



                                            </div>
                                        </div>
                                    </div>








                                    {/*<div className="row g-gs">
                                         <form className="row mt-4" onSubmit={addWhatsNewForm.handleSubmit}>
                                            <div className="col-md-5">
                                                <div className="row g-gs">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="form-control-wrap whatNewInput">
                                                                <label className="form-label">Post Title</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-lg"
                                                                    placeholder="Title"
                                                                    name='title'
                                                                    {...addWhatsNewForm.getFieldProps("title")}
                                                                />
                                                                {addWhatsNewForm.touched.title && addWhatsNewForm.errors.title
                                                                    && <div className="invalid-feedback" style={{ display: "block" }}>
                                                                        {addWhatsNewForm.errors.title}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <div className="form-control-wrap whatNewInput">
                                                                <label className="form-label">Post Description</label>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={addWhatsNewForm.values.description}
                                                                    onReady={editor => {
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        addWhatsNewForm.setFieldValue("description", data)
                                                                    }}
                                                                />
                                                            </div>
                                                            {addWhatsNewForm.touched.description && addWhatsNewForm.errors.description
                                                                ? <div className="invalid-feedback" style={{ display: "block" }}>
                                                                    {addWhatsNewForm.errors.description}
                                                                </div>
                                                                : ''
                                                            }
                                                        </div>
                                                    </div>
                                                    <label className="form-label mt-2">Schedule Date & Time</label>
                                                    <div className="col-md-12 mt-0">
                                                        <div className="form-control-wrap whatNewInput">
                                                            <input
                                                                type="datetime-local"
                                                                min={moment.utc(moment()).tz(userTimeZone).format('yyyy-MM-DDTHH:mm')}
                                                                className="form-control form-control-xl form-control-outlined"
                                                                id="outlined-date-picker"
                                                                name='start_date_time'
                                                                value={currentDateTime}
                                                                onChange={e => {
                                                                    setCurrentDateTime(e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                

                                                    <div className="col-md-12 mt-2">
                                                        <div className="discountExpireBox mb-3">
                                                            <span>Default</span>
                                                            <div className="custom-control custom-switch">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="customSwitch1"
                                                                    checked={isPostDefault}
                                                                    value={isPostDefault}
                                                                    onChange={(e) => {
                                                                        setIsPostDefault(!isPostDefault)
                                                                        addWhatsNewForm.setFieldValue("is_default", !isPostDefault)
                                                                        setPostExpiry(false)
                                                                    }}
                                                                />
                                                                <label className="custom-control-label" htmlFor="customSwitch1">Make Default </label>
                                                            </div>
                                                        </div>
                                                        <div className="discountExpireBox">
                                                            <span>Expiration Date</span>
                                                            <div className="custom-control custom-switch">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="customSwitch2"
                                                                    checked={postExpiry}
                                                                    value={postExpiry}
                                                                    onChange={(e) => {
                                                                        setPostExpiry(!postExpiry)
                                                                        setIsPostDefault(false)
                                                                    }}
                                                                />
                                                                <label className="custom-control-label" htmlFor="customSwitch2">Add Expiration Date </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {postExpiry &&
                                                        <>
                                                            <label className="form-label mt-2">Expiration Date & Time</label>
                                                            <div className="col-md-12 mt-0">
                                                                <div className="form-control-wrap whatNewInput">
                                                                    <input
                                                                        type="datetime-local"
                                                                        min={moment.utc(moment().add(1, 'days')).tz(userTimeZone).format('yyyy-MM-DDTHH:mm')}
                                                                        className="form-control form-control-xl form-control-outlined date-picker"
                                                                        id="outlined-date-picker"
                                                                        name='post_exp_time'
                                                                        value={expDateTime}
                                                                        onChange={e => {
                                                                            setExpDateTime(e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                       

                                                        </>
                                                    }
                                                </div>
                                            </div>



                                            <div className="col-md-5">
                                               
                                                <label className="form-label">Screen Image</label>
                                                {postImage
                                                    ? <div>
                                                        <img src={URL.createObjectURL(postImage)} alt="Screen Image" style={{ width: "250px" }} />
                                                        <em
                                                            role="button"
                                                            className="far fa-solid fa-2x fa-rectangle-xmark"
                                                            style={{ position: "absolute" }}
                                                            onClick={e => {
                                                                setPostImage("")
                                                                addWhatsNewForm.setFieldValue("image", "")
                                                            }}
                                                        />
                                                    </div>
                                                    : <div className=""  {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        {isDragActive
                                                            ?
                                                            <div className='d-flex justify-content-center align-items-center border border-3 border-dashed rounded-3' style={{ width: "350px", height: "150px", backgroundColor: "whitesmoke" }}>
                                                                <h6>Drop the image here...</h6>
                                                            </div>
                                                            : <div className='d-flex justify-content-center align-items-center border border-3 border-dashed rounded-3 flex-column p-2'>
                                                                <img className="imgUploadIcon" src="images/Image.png" alt="user-avatar" />
                                                                <div className="btn uploadTxt">
                                                                    <em className="icon ni ni-upload" /> Upload Image
                                                                </div>
                                                                <div className="dz-message" data-dz-message="">
                                                                    <span className="dz-message-or">Select or drag & drop onboarding screen image here.</span><br />
                                                                    <span className="dz-message-text">File Format jpeg, png Accepted Size 1000x1000</span>
                                                                </div>
                                                            </div>
                                                        }
                                                        {addWhatsNewForm.touched.image && addWhatsNewForm.errors.image
                                                            ? <div className="invalid-feedback" style={{ display: "block" }}>
                                                                {addWhatsNewForm.errors.image}
                                                            </div>
                                                            : ''
                                                        }
                                                    </div>
                                                }

                                                {btnLoading
                                                    ? <div className='flex justify-center items-center mt-3'>
                                                        <Oval
                                                            height="30"
                                                            width="30"
                                                            color='var(--ps-main)'
                                                            secondaryColor="var(--ps-main)"
                                                            ariaLabel='oval-loading'
                                                            strokeWidth={4}
                                                            strokeWidthSecondary={4}
                                                            visible={true}
                                                        />
                                                    </div>
                                                    : <button type="submit" className="btn btn-primary lightBlue d-inline w-100 mt-3">Upload Post</button>
                                                }

                                            </div>
                                        </form>
                                    </div> */}



                                </div>
                            </div>
                        </div>
                    </div>

                </Container>
            }
        </>
    );
}

export default WhatsNew;
